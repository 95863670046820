import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [token, setToken] = useLocalStorage("token", null);
  const [refreshToken, setrefreshToken] = useLocalStorage("refreshToken", null);
  const [role, setRole] = useLocalStorage("role", null);
  const [userID, setUserID] = useLocalStorage("userID", null);
  const [snowRole, setSnowRole] = useLocalStorage("snowRole", null);
  const [snowAssets, setSnowAssets] = useLocalStorage("snowAssets", null);
  const [snowUserGroups, setSnowUserGroups] = useLocalStorage("snowUserGroups", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data, user, userData, snowUser, snowAssets, snowUserGroups) => {
    console.log(user);
    setUser(JSON.stringify(userData));
    setToken(data.access_token);
    setrefreshToken(data.refresh_token);
    setRole(userData.roles[0].name);
    setSnowRole(snowUser[0].roles);
    setUserID(snowUser[0].sys_id); 
    let assets = []
    for (const [i, row] of snowAssets.entries()) {
      assets.push(
        {
          "user_id": snowUser[0].sys_id,
          "user_name": snowUser[0].user_name,
          "device": row.display_name
        })
    }
    setSnowAssets(JSON.stringify({details: assets}));
    
    setSnowUserGroups(JSON.stringify(snowUserGroups));

    //setUserID(userID);
    navigate("/home");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};