import shadows from "@mui/material/styles/shadows";
import { colors } from "./config";
import { Padding } from "@mui/icons-material";
import zIndex from "@mui/material/styles/zIndex";

export const styles = {
    chatWidget: {
        // Position
        position: "fixed",
        bottom: "30px",
        right: "30px",
        backgroundColor: colors.primary,
        // Padding
        paddingLeft: "5px",
        paddingRight: "5px",
        paddingTop: "5px",
        paddingBottom: "5px",
        // Border
        borderRadius: "50%",
        cursor: "pointer",
        boxShadow: "1px 1px 10px 1px grey"
    },

    chatWidgetText: {
        color: "white",
        fontSize: "15px",
        marginLeft: "5px",
    },
    chatWidgetIcon: {
        color: "white",
        fontSize: "40px"
    },

    chatScreen: {
        position: "relative",
        marginTop: "3%",
        left: "1%",
        width: "95%",
        height: "60%",
        borderRadius: 5,
        bgcolor: "white",
        '&:hover': {
            boxShadow: "2px 2px 10px 2px grey"
        },
        boxShadow: "1px 1px 10px 1px grey",
        zIndex: 99
    },

    chatModal: {
        width: "100%",
        bgcolor: 'white',
        border: '0px solid #000',
        boxShadow: 5,
        p: 0,
        height: '100%',
        borderRadius: '5px'
    },

    reactchatbotkitchatbotmessage: {
        marginLeft: '0px !important'
    },

    chatbotloadercontainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
      }
      
      
}

/*
.react-chatbox-kit-container {
        width: "100%"
    }
        */