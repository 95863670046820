import ChatWidget from "../components/ChatWidget";
import PrimarySearchAppBar from "../components/PrimarySearchAppBar";
import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import axios from "axios";
import { useEffect, useState } from "react";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Box, Select, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, CircularProgress } from "@mui/material";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { LoadingButton } from "@mui/lab";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import Alert from '@mui/material/Alert';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from 'moment';
import axiosInstance from './../axios';
import { useSearchParams } from "react-router-dom";
import ResourceCardMsg from "./ChatWidget/resourceCardMsg";
const ChatWidgetWMemo = React.memo(ChatWidget)

let botLoaded = false;

const hostName = 'https://kogniv-iservice-gvgmhpeuf5eaaghu.centralus-01.azurewebsites.net/api/main';
//const hostName = 'http://localhost:8000/api/main'

const MyIncidents = () => {
  const [srList, setSrList] = useState([]);
  const [srListClosed, setSrListClosed] = useState([]);
  const [visible, setVisible] = useState(false);
  const [inc, setInc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [loadedLists, setLoadedLists] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('success');
  const [incState, setIncState] = useState('open');
  let rows = [];
  const [userList, setUserList] = useState([]);
  const [userGroupList, setUserGroupList] = useState([]);
  const [ciList, setCiList] = useState([]);
  const [incidentsList, setIncidentsList] = useState([]);
  const [changesList, setChangesList] = useState([]);
  const [problemsList, setProblemsList] = useState([]);
  const [openUser, setOpenUser] = useState(false);
  const [openNewInc, setOpenNewInc] = useState(false);
  const [newIncImpact, setNewIncImpact] = useState('');
  const [newIncUrgency, setNewIncUrgency] = useState('');
  const [newState, setNewState] = useState('');
  const [resolutionCode, setResolutionCode] = useState('');
  const [newKnowledge, setNewKnowledge] = useState(false);
  const [shortDescForBot, setShortDescForBot] = useState(null);
  const [botResponse, setBotResponse] = useState(null);
  const [botLoading, setBotLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [incidentFilterModel, setIncidentFilterModel] = useState({ filterModel: { items: [] } })
  const [refContent, setRefContent] = useState({ kb: [], inc: [] });

  let filterParam = searchParams.get("filter")
  const handleSelectChange = (event) => {
    setNewIncImpact(event.target.value);
  };

  const handleSelectChangeUrg = (event) => {
    setNewIncUrgency(event.target.value);
  };

  const handleSelectChangeIncState = (event) => {
    setNewState(event.target.value);
  };

  const handleSelectChangeIncResolutionCode = (event) => {
    setResolutionCode(event.target.value);
  };

  const handleKnowledgeToggle = (event) => {
    setNewKnowledge(event.target.checked)
  }

  const handleClose = () => {
    setOpenUser(false);
  };

  const handleNewIncOpen = () => {
    setOpenNewInc(true);
    setNewIncImpact('');
    setNewIncUrgency('');
  };

  const handleNewIncClose = () => {
    setOpenNewInc(false);
    setNewIncImpact('');
    setNewIncUrgency('');
  };

  const [modalData, setModalData] = useState([]);
  const handleChange = (event, incState) => {
    setIncState(incState);
    rows = srListClosed;
  };

  function handleModalOpenUsers(type, field) {
    let modalData = {}
    modalData.type = type;
    modalData.fieldName = field;
    modalData.data = {}
    if (type === 'user') {
      modalData.data.columns = [
        { field: 'id', headerName: 'ID', width: 70, hideable: true },
        { field: 'sys_id', headerName: 'Sys ID', width: 70, hideable: true },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'sys_domain.value', headerName: 'User Group', width: 300, hideable: true },
        { field: 'first_name', headerName: 'First Name', width: 130, sortable: true, hideable: true },
        { field: 'last_name', headerName: 'Last Name', width: 130, hideable: true },
        { field: 'email', headerName: 'Email', width: 300, hideable: true }

      ];
      modalData.data.rows = userList
    } else if (type === 'userGrp') {
      modalData.data.columns = [
        { field: 'id', headerName: 'ID', width: 70, hideable: true },
        { field: 'sys_id', headerName: 'Sys ID', width: 70, hideable: true },
        { field: 'name', headerName: 'Name', width: 500 }
      ];
      console.log("user gorup list>>>>>  ", userGroupList)
      modalData.data.rows = userGroupList
    } else if (type === 'ci') {
      modalData.data.columns = [
        { field: 'id', headerName: 'ID', width: 70, hideable: true },
        { field: 'sys_id', headerName: 'Sys ID', width: 70, hideable: true },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'manufacturer', headerName: 'Manufacturer', width: 130, sortable: true, hideable: true },
        { field: 'subcategory', headerName: 'Class', width: 130, hideable: true },
        { field: 'assigned_to.value', headerName: 'Assigned To', width: 300, hideable: true }
      ];
      console.log("ci list>>>>  ", ciList)
      modalData.data.rows = ciList
    } else if (type === 'inc') {
      modalData.data.columns = [
        { field: 'id', headerName: 'ID', width: 70, hideable: true },
        { field: 'sys_id', headerName: 'Sys ID', width: 70, hideable: true },
        { field: 'number', headerName: 'Number', width: 200 },
        { field: 'assigned_to.value', headerName: 'Assigned To', width: 130, sortable: true, hideable: true },
        { field: 'assignment_group.value', headerName: 'Assignment Group', width: 130, hideable: true },
        { field: 'short_description', headerName: 'Short Description', width: 300, hideable: true }
      ];
      modalData.data.rows = incidentsList
    } else if (type === 'prb') {
      modalData.data.columns = [
        { field: 'id', headerName: 'ID', width: 70, hideable: true },
        { field: 'sys_id', headerName: 'Sys ID', width: 70, hideable: true },
        { field: 'number', headerName: 'Number', width: 200 },
        { field: 'assigned_to.value', headerName: 'Assigned To', width: 130, sortable: true, hideable: true },
        { field: 'assignment_group.value', headerName: 'Assignment Group', width: 130, hideable: true },
        { field: 'short_description', headerName: 'Short Description', width: 300, hideable: true }
      ];
      modalData.data.rows = problemsList
    } else if (type === 'chg') {
      modalData.data.columns = [
        { field: 'id', headerName: 'ID', width: 70, hideable: true },
        { field: 'sys_id', headerName: 'Sys ID', width: 70, hideable: true },
        { field: 'number', headerName: 'Number', width: 200 },
        { field: 'assigned_to.value', headerName: 'Assigned To', width: 130, sortable: true, hideable: true },
        { field: 'assignment_group.value', headerName: 'Assignment Group', width: 130, hideable: true },
        { field: 'short_description', headerName: 'Short Description', width: 300, hideable: true }
      ];
      modalData.data.rows = changesList
    }
    modalData.data.rowLength = modalData.data.rows.length;
    modalData.data.maxColumns = 10

    modalData.paginationModel = { page: 0, pageSize: 20 };
    console.log('modaldata >>>>  ', modalData)
    setModalData(modalData);
    setOpenUser(true)
  }

  let userRole = localStorage.getItem("role").toLocaleLowerCase();
  userRole = userRole.substring(1, userRole.length - 1);
  //userRole = 'itil';
  let userID = localStorage.getItem("userID");
  userID = userID.substring(1, userID.length - 1)
  console.log("typeof userid::::::::::::", userID)

  const getSRList = async () => {
    try {

      filterMyAssignment(filterParam)

      setTableLoading(true);
      const token = localStorage.getItem("token");
      let user = localStorage.getItem("user").toLocaleLowerCase();
      user = user.substring(1, user.length - 1)
      axios.defaults.headers.post['x-api-key'] = `test`;
      axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.post['Content-Type'] = 'application/json';
      let data = {}
      if (userRole === 'employee' || userRole === 'approver') {
        data = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "incident",
            "methodType": "get",
            "query": {
              "sysparm_query": "active=true^caller_id="+userID,
              "sysparm_fields": "number,state,sys_id,sys_created_on,priority,short_description,due_date,assigned_to,assignment_group,caller_id",
              "sysparm_display_value": "true"
            }
          }
        }
      } else if (userRole === 'itil' || userRole === 'super_admin') {
        data = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "incident",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "active": true
              },
              "sysparm_fields": "number,state,sys_id,sys_created_on,priority,short_description,due_date,assigned_to,assignment_group,caller_id",
              "sysparm_display_value": "true"
            }
          }
        }
      }

      let res = axios.post(`${hostName}`, data).then((res) => {
        if (res.status === 200) {
          let data = [];
          let closedData = [];
          if (userRole === 'employee' || userRole === 'approver') {
            for (const [i, row] of res.data.data.result.entries()) {
              row.id = i
              if (typeof row.assigned_to === 'object') {
                row.assigned_to_table = row.assigned_to.display_value
                row.assigned_to_val_table = row.assigned_to.link
              }
              if (typeof row.assignment_group === 'object') {
                row.assignment_group_table = row.assignment_group.display_value
                row.assignment_group_val_table = row.assignment_group.link
              }
              if (typeof row.caller_id === 'object') {
                row.caller_val_table = row.caller_id.link
              }
              data.push(row)
            }

          } else if (userRole === 'itil' || userRole === 'super_admin') {
            console.log("data in itil>>> ", res.data)
            for (const [i, row] of res.data.data.result.entries()) {
              row.id = i
              if (typeof row.assigned_to === 'object') {
                row.assigned_to_table = row.assigned_to.display_value
                row.assigned_to_val_table = row.assigned_to.link
              }
              if (typeof row.assignment_group === 'object') {
                row.assignment_group_table = row.assignment_group.display_value
                row.assignment_group_val_table = row.assignment_group.link
              }
              if (typeof row.caller_id === 'object') {
                row.caller_val_table = row.caller_id.link
              }
            }
            data = res.data.data.result;
          }
          const SR = data;
          setSrList(SR);
          setSrListClosed(closedData);
          setTableLoading(false);

        } else {
          console.log(res.status);
          setTableLoading(false);

        }
      })

      if (!loadedLists && (userRole === 'itil' || userRole === 'super_admin')) {
        data = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "sys_user",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "active": true
              },
              "sysparm_fields": "sys_id,name,first_name,last_name,email,sys_domain"
            }
          }
        }
        res = axios.post(`${hostName}`, data).then((res) => {
          if (res.status === 200) {
            let data = [];
            for (const [i, row] of res.data.data.result.entries()) {
              row.id = i
              data.push(row)
            }
            setUserList(data.map(({ id, sys_id, name, first_name, last_name, email }) => ({ id, sys_id, name, first_name, last_name, email })))
          }
        })

        data = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "sys_user_group",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "active": true
              },
              "sysparm_fields": "sys_id,name"
            }
          }
        }
        res = axios.post(`${hostName}`, data).then((res) => {
          if (res.status === 200) {
            let data1 = [];
            for (const [i, row] of res.data.data.result.entries()) {
              row.id = i
              data1.push(row)
            }
            setUserGroupList(data1.map(({ id, sys_id, name }) => ({ id, sys_id, name })))
          }
        })


        data = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "cmdb_ci",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "active": true
              },
              "sysparm_fields": "sys_id,name,manufacturer,subcategory,assigned_to"
            }
          }
        }
        res = axios.post(`${hostName}`, data).then((res) => {
          if (res.status === 200) {
            let data = [];
            for (const [i, row] of res.data.data.result.entries()) {
              row.id = i
              data.push(row)
            }
            setCiList(data.map(({ id, sys_id, name, manufacturer, subcategory, assigned_to }) => ({ id, sys_id, name, manufacturer, subcategory, assigned_to })))
          }
        })


        data = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "incident",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "active": true
              },
              "sysparm_fields": "sys_id,number,assigned_to,assignment_group,short_description"
            }
          }
        }
        res = axios.post(`${hostName}`, data).then((res) => {
          if (res.status === 200) {
            let data = [];
            for (const [i, row] of res.data.data.result.entries()) {
              row.id = i
              data.push(row)
            }
            setIncidentsList(data.map(({ id, sys_id, number, assigned_to, assignment_group, short_description }) => ({ id, sys_id, number, assigned_to, assignment_group, short_description })))
          }
        });

        data = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "change_request",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "active": true
              },
              "sysparm_fields": "sys_id,number,assigned_to,assignment_group,short_description"
            }
          }
        }
        res = axios.post(`${hostName}`, data).then((res) => {
          if (res.status === 200) {
            let data = [];
            for (const [i, row] of res.data.data.result.entries()) {
              row.id = i
              data.push(row)
            }
            setChangesList(data.map(({ id, sys_id, number, assigned_to, assignment_group, short_description }) => ({ id, sys_id, number, assigned_to, assignment_group, short_description })))
          }
        })

        data = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "problem",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "active": true
              },
              "sysparm_fields": "sys_id,number,assigned_to,assignment_group,short_description"
            }
          }
        }
        res = axios.post(`${hostName}`, data).then((res) => {
          if (res.status === 200) {
            let data = [];
            for (const [i, row] of res.data.data.result.entries()) {
              row.id = i
              data.push(row)
            }
            setProblemsList(data.map(({ id, sys_id, number, assigned_to, assignment_group, short_description }) => ({ id, sys_id, number, assigned_to, assignment_group, short_description })))
          }
        });
        setLoadedLists(true);
      }

    }
    catch (error) {
      console.log("error while fetching inc list:::", error)

      setTableLoading(false);

    }
  };
  useEffect(() => {
    getSRList()
  }, []);

  const columns = [
    { field: 'id', headerName: 'ID', width: 70, hideable: true, filterable: false },
    { field: 'sys_id', headerName: 'Sys ID', width: 70, hideable: true, filterable: false },
    { field: 'number', headerName: 'Incident ID', width: 130, filterable: true },
    { field: 'sys_created_on', headerName: 'Created Date', width: 200, sortable: true, hideable: true, filterable: false },
    { field: 'priority', headerName: 'Priority', width: 100, hideable: true, filterable: true },
    { field: 'state', headerName: 'State', width: 100, hideable: true, filterable: true },
    { field: 'short_description', headerName: 'Short Description', sortable: false, width: 300, hideable: true, filterable: false },
    { field: 'due_date', headerName: 'Due Date', width: 100, sortable: true, hideable: true, filterable: true },
    { field: 'assigned_to_table', headerName: 'Assigned To', width: 150, sortable: true, hideable: true, filterable: true },
    { field: 'assignment_group_table', headerName: 'Assignment Group', width: 250, sortable: true, hideable: true, filterable: true },
    { field: 'assigned_to_val_table', headerName: 'Assigned To val', width: 150, sortable: true, hideable: true, filterable: true },
    { field: 'assignment_group_val_table', headerName: 'Assignment Group val', width: 250, sortable: true, hideable: true, filterable: true },
    { field: 'caller_val_table', headerName: 'Caller ID', width: 250, sortable: true, hideable: true, filterable: true }
  ];

  const filterMyAssignment = (filterParam) => {
    if (filterParam && (userRole === 'itil' || userRole === 'super_admin')) {
      if (filterParam.includes('grp_assigned')) {
        /*incidentFilterModel = {
          filterModel: {
            items: [{ field: 'assignment_group_val_table', operator: 'contains', value: userID }]
          }
        }*/
        setIncidentFilterModel({ filterModel: { items: [] } })
        setSearchParams("filter=grp_assigned")
      } else if (filterParam.includes('assigned')) {
        let filter = {
          filterModel: {
            items: [{ field: 'assigned_to_val_table', operator: 'contains', value: userID }]
          }
        }
        setIncidentFilterModel(filter)
        setSearchParams("filter=assigned")
      } else if (filterParam.includes('my_inc')) {
        let filter = {
          filterModel: {
            items: [{ field: 'caller_val_table', operator: 'contains', value: userID }]
          }
        }
        setIncidentFilterModel(filter)
        setSearchParams("filter=my_inc")
      }
    } else {
      setIncidentFilterModel({ filterModel: { items: [] } })
      setSearchParams("filter=null")
    }
  }



  if (incState === 'open') {
    rows = srList
  } else {
    rows = srListClosed
  }

  const data = {
    "rows": rows,
    "columns": columns,
    "rowLength": rows.length,
    "maxColumns": 50
  }

  const paginationModel = { page: 0, pageSize: 10 };

  async function getKnowledgeresponse(short_description) {
    setBotLoading(true);
    localStorage.setItem('aa_conv_id', null)
    console.log('bot loading:::', botLoading)
    try {
      setShortDescForBot(short_description);
      const token = localStorage.getItem("token");
      let customHeaders = {};
      customHeaders['Authorization'] = 'Bearer ' + token.substring(1, token.length - 1);
      customHeaders['Access-Control-Allow-Origin'] = '*';
      customHeaders['Content-Type'] = 'application/x-www-form-urlencoded';
      let message = "How can I assist you today?";
      const response = await axiosInstance.post('https://api.kogniv.com/api/v1/chat/agent-assist', {
        //"message": `Give me the resolution steps in less than 6 steps in a formatted way for the issue: ${short_description}. Steps must be enclosed in HTML tags where the steps should be in list tag, header with underline and the subheaders with the bold tags with proper indentations.`
        "message": short_description
      }, { customHeaders });
      console.log('bot loading end:::', botLoading)
      if (response.status === 200) {
        localStorage.setItem('aa_conv_id', response.data.thread_id)
        if (response.data.response === "") {
          message = "How can I assist you today?"
        } else {
          message = response.data.response
        }
      }
      setBotResponse(message);
      setBotLoading(false);
    } catch (err) {
      console.log('bot loading end:::', botLoading)
      console.log("error in getting knowledge base", err);
      setBotResponse({ response: { msg: "Sorry, Unable to get related information" } })
      setBotLoading(false);
    }
    botLoaded = true;

  }

  const fetchRefs = async () => {
    let fetchedRefs = false;
    let fetchedCount = 0;
    if (inc && inc.kb && inc.kb.length > 0) {
      for (const [i, ref] of inc.kb.entries()) {
        axios.defaults.headers.post['x-api-key'] = `test`;
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        let data = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "number": ref
              }
            }
          }
        };
        if (ref.includes("KB")) {
          data.params.entity = "knowledge";
          data.params.query.sysparm_fields = "number,short_description,text,published"
        } else if (ref.includes("INC")) {
          data.params.entity = "incident"
          data.params.query.sysparm_fields = "number,short_description,description,comments_and_work_notes,close_notes"
          data.params.query.sysparm_display_value = "true"
        }
        try {
          let res = await axios.post(`${hostName}`, data);
          if (res.status === 200) {
            if (res.data.data.result.length > 0) {
              let refTemp = refContent;
              console.log("ref:::", res)
              if (ref.includes("KB")) {
                refTemp.kb.push(res.data.data.result[0]);
              } else if (ref.includes("INC")) {
                let inc = res.data.data.result[0];
                console.log("incident:: ", inc)

                inc.comments_and_work_notes = inc.comments_and_work_notes.replace('\n\n', '\n');
                let tempworkNotes = inc.comments_and_work_notes.split('\n');
                let workNotes = [];
                let note = {}
                tempworkNotes = tempworkNotes.filter(e => e !== '');
                for (const [i, row] of tempworkNotes.entries()) {

                  if (i % 2 === 0) {
                    note = {}
                    note.date = row;
                  } else {
                    note.text = row;
                    workNotes.push(note);
                  }
                }
                console.log("workNotes:: ", workNotes)
                inc.workNotes = workNotes;

                refTemp.inc.push(inc);
              }
              fetchedRefs = refTemp;
              setRefContent(refTemp)
            }
            fetchedCount++;
            if (fetchedCount === inc.kb.length) {
              fetchedRefs = true;
            }
          }
        } catch (err) {
          console.log("error while fetching reference:: ", ref)
          console.log("Error stack::", err);
          fetchedCount++;
          if (fetchedCount === inc.kb.length) {
            fetchedRefs = true;
          }
        }
      }
    }
  }

  const handleRowClick = async (params, callBot) => {
    console.log("row click called")
    setAlert(false);
    let tempworkNotes = [];
    if ((userRole === 'itil' || userRole === 'super_admin') && callBot!== false) {
      getKnowledgeresponse(params.row.short_description);
    } else {
      setBotLoading(false)
    }

    axios.defaults.headers.post['x-api-key'] = `test`;
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    let data = {
      "appID": 1,
      "params": {
        "data": null,
        "entity": "incident",
        "methodType": "get",
        "query": {
          "sysparm_query": {
            "sys_id": params.row.sys_id
          },
          "sysparm_display_value": "true"
        }
      }
    }
    let res = await axios.post(`${hostName}`, data);
    if (res.status === 200) {
      if (res.data.data.result.length > 0) {
        params.row = res.data.data.result[0]
      }
    }

    setNewIncImpact(params.row.impact);
    setNewIncUrgency(params.row.urgency);
    setNewState(params.row.state);
    if (params.row.knowledge === 'true') {
      setNewKnowledge(true);
    }

    setResolutionCode(params.row.close_code);
    if (params.row.comments_and_work_notes === "") {
      params.row.workNotes = [];
    } else {
      params.row.comments_and_work_notes = params.row.comments_and_work_notes.replace('\n\n', '\n');
      tempworkNotes = params.row.comments_and_work_notes.split('\n');
      let workNotes = [];
      let note = {}
      tempworkNotes = tempworkNotes.filter(e => e !== '');
      for (const [i, row] of tempworkNotes.entries()) {

        if (i % 2 === 0) {
          note = {}
          note.date = row;
        } else {
          note.text = row;
          if (row.includes("Attached KB Article:")) {
            if (params.row.kb) {
              params.row.kb.push(row.split(": ")[1])
            } else {
              params.row.kb = [];
              params.row.kb.push(row.split(": ")[1])
            }
          } else {
            workNotes.push(note);
          }
        }
      }
      console.log("workNotes:: ", workNotes)
      params.row.workNotes = workNotes;
    }

    let fields = ['assigned_to', 'assignment_group', 'cmdb_ci', 'parent_incident', 'rfc', 'caused_by', 'problem_id'];
    for (const [i, fld] of fields.entries()) {
      if (params.row[fld] !== null && params.row[fld] !== '') {
        let sys_id = params.row[fld].link.split('/');
        sys_id = sys_id[sys_id.length - 1];
        params.row[fld].value = sys_id;
      } else {
        params.row[fld] = {};
        params.row[fld].value = '';
        params.row[fld].display_value = '';

      }
    }
    if (params.row.kb) {
      params.row.kb = [...new Set(params.row.kb)]
    }
    setInc(params.row);
    if (params.row.kb) {
      fetchRefs();
    }
    setVisible(true);
  };

  //const memoizedRowClick = React.useCallback(handleRowClick, []);
  //console.log("memoizedUpdateCount::: ", memoizedRowClick)

  const backToList = () => {
    setVisible(false);
    setShortDescForBot(null);
    setBotResponse(null);
    setBotLoading(true);
    setAlert(false);
    getSRList();
    setRefContent({ kb: [], inc: [] })
  }

  const handleUserModalClick = async (params) => {
    console.log(params);
    let fieldName = modalData.fieldName;
    let inctemp = null;
    switch (fieldName) {
      case 'assigned_to':
        inc.new_assigned_to = params.row.sys_id;
        if (inc.assigned_to === '') {
          inc.assigned_to = {}
        }
        inc.assigned_to.value = params.row.sys_id;
        inc.assigned_to.display_value = params.row.name;
        document.getElementById('assigned_to_val').value = params.row.name;
        break;
      case 'assignment_group':
        inc.new_assignment_group = params.row.sys_id;
        if (inc.assignment_group === '') {
          inc.assignment_group = {}
        }
        inc.assignment_group.value = params.row.sys_id;
        inc.assignment_group.display_value = params.row.name;
        document.getElementById('assignGrp').value = params.row.name;
        break;
      case 'cmdb_ci':
        inc.new_cmdb_ci = params.row.sys_id;
        if (inc.cmdb_ci === '') {
          inc.cmdb_ci = {}
        }
        inc.cmdb_ci.value = params.row.sys_id;
        inc.cmdb_ci.display_value = params.row.name;
        document.getElementById('congif_item').value = params.row.name;
        break;
      case 'parent_incident':
        inc.new_parent_incident = params.row.sys_id;
        if (inc.parent_incident === '') {
          inc.parent_incident = {}
        }
        inc.parent_incident.value = params.row.sys_id;
        inc.parent_incident.display_value = params.row.number;
        document.getElementById('parent_incident').value = params.row.number;
        break;
      case 'rfc':
        inc.new_rfc = params.row.sys_id;
        if (inc.rfc === '') {
          inc.rfc = {}
        }
        inc.rfc.value = params.row.sys_id;
        inc.rfc.display_value = params.row.number;
        document.getElementById('rfc').value = params.row.number;
        break;
      case 'caused_by':
        inc.new_caused_by = params.row.sys_id;
        if (inc.caused_by === '') {
          inc.caused_by = {}
        }
        inc.caused_by.value = params.row.sys_id;
        inc.caused_by.display_value = params.row.number;
        document.getElementById('caused_by').value = params.row.number;
        break;
      case 'problem_id':
        inc.new_problem_id = params.row.sys_id;
        if (inc.problem_id === '') {
          inc.problem_id = {}
        }
        inc.problem_id.value = params.row.sys_id;
        inc.problem_id.display_value = params.row.number;
        document.getElementById('problem_id').value = params.row.number;
        break;
    }
    inctemp = inc;
    setInc(inctemp);
    setOpenUser(false);
  }

  const handleFormSubmit = async (buttonName) => {
    setLoading(true);
    const comments = document.getElementById('new_notes').value;
    const resNotes = document.getElementById('resolution_notes').value;
    const desc = document.getElementById("desc").value
    const shortdesc = document.getElementById("short_desc").value

    inc.close_notes = resNotes;
    console.log("comments::: ", comments)
    console.log("knowledge:::: ", newKnowledge)
    const payload = {}
    payload.short_description = shortdesc;
    payload.description = desc
    if (comments !== '' && comments) {
      payload.comments = comments
    }
    let invalidSubmit = false;
    if (buttonName === 'resolve' || newState === 'Closed' || newState === 'Resolved') {
      if (resolutionCode === '' || inc.close_notes === '') {
        invalidSubmit = true;
      } else {

        //payload.closed_by = userID;
        payload.close_notes = inc.close_notes;
        payload.close_code = resolutionCode;
        payload.resolved_at = moment().format('YYYY-MM-DD HH:mm:ss')

      }
    }

    if (invalidSubmit) {
      setAlertContent("Fill resolution information to resolve this incident");
      setAlert(true);
      //setTimeout(setAlert(false),3000)
      setAlertType("error");
      setLoading(false);
    } else {
      inc.new_impact = newIncImpact;
      inc.new_knowledge = newKnowledge
      inc.new_urgency = newIncUrgency;
      inc.new_state = newState;
      if (payload.comments) {
        inc.workNotes.unshift({ text: payload.comments })
      }
      let fields = ['assigned_to', 'assignment_group', 'impact', 'knowledge', 'urgency', 'state', 'cmdb_ci', 'parent_incident', 'rfc', 'caused_by', 'problem_id'];
      for (const [i, row] of fields.entries()) {
        let field = 'new_' + row;
        if (inc[field] && inc[field] !== '') {
          payload[row] = inc[field]
        }
      }

      console.log("payload>>>>", payload);
      try {
        const token = localStorage.getItem("token");
        axios.defaults.headers.patch['Authorization'] = `Bearer ${token.substring(1, token.length - 1)}`;
        axios.defaults.headers.patch['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.patch['Content-Type'] = 'application/json';

        let data = {
          "appID": 1,
          "params": {
            "data": payload,
            "sysId": inc.sys_id,
            "entity": "incident",
            "methodType": "patch",
            "query": null
          }
        }
        let res = await axios.post(`${hostName}`, data);
        console.log("response>>>>", res.status)
        setLoading(false);
        if (res.status === 200) {
          setAlertContent("Saved successfully!");
          setAlert(true);
          //setTimeout(setAlert(false),3000)
          setAlertType("success");
          document.getElementById('new_notes').value = '';
          if (shortDescForBot !== shortdesc) {
            setShortDescForBot(shortdesc)
          }
          handleRowClick({row:inc}, false)
        } else {
          setAlertContent("Error while updating the notes, please try again.");
          setAlert(true);
          //setTimeout(setAlert(false),3000)
          setAlertType("error");
        }

      } catch (error) {
        console.log("catch>>>", error);
        setLoading(false);
        setAlertContent("Error while updating the notes, please try again.");
        setAlert(true);
        //setTimeout(setAlert(false),3000)
        setAlertType("error");
      }
    }
  }

  const attachToInc = async (kb) => {
    console.log("KB to be attached to inc:: ", kb)
    try {
      const token = localStorage.getItem("token");
      axios.defaults.headers.patch['Authorization'] = `Bearer ${token.substring(1, token.length - 1)}`;
      axios.defaults.headers.patch['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.patch['Content-Type'] = 'application/json';

      let data = {
        "appID": 1,
        "params": {
          "data": { comments: 'Attached KB Article: ' + kb },
          "sysId": inc.sys_id,
          "entity": "incident",
          "methodType": "patch",
          "query": null
        }
      }
      let res = await axios.post(`${hostName}`, data);
      console.log("response>>>>", res.status)
      setLoading(false);
      if (res.status === 200) {
        setAlertContent("Saved successfully!");
        setAlert(true);
        //setTimeout(setAlert(false),3000)
        setAlertType("success");
        inc.workNotes.unshift({ text: 'Attached KB Article: ' + kb });

        document.getElementById('new_notes').value = '';
      } else {
        setAlertContent("Error while attaching the KB article, please try again.");
        setAlert(true);
        //setTimeout(setAlert(false),3000)
        setAlertType("error");
      }

    } catch (error) {
      console.log("catch>>>", error);
      setLoading(false);
      setAlertContent("Error while attaching the KB article, please try again.");
      setAlert(true);
      //setTimeout(setAlert(false),3000)
      setAlertType("error");
    }
  }

  const chatBotChild = React.useMemo(() => {
    return <ChatWidgetWMemo botName='knowledgeAssist' entityType='incident' /*shortDesc={memoizedShortDesc}*/ entity={botResponse} attachment={attachToInc} /*botLoaded={botLoaded}*/ />
  }, [botResponse])


  const submitNewIncident = async (form) => {
    let payload = {};
    payload.short_description = form.short_description;
    payload.description = form.description;
    payload.impact = newIncImpact
    payload.urgency = newIncUrgency
    payload.caller_id = userID
    axios.defaults.headers.post['x-api-key'] = `test`;
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    let data = {
      "appID": 1,
      "params": {
        "data": payload,
        "entity": "incident",
        "methodType": "post",
        "query": null
      }
    }
    handleNewIncClose();
    let res = await axios.post(`${hostName}`, data);
    console.log("response>>>>", res.status);
    if (res.status === 200) {
      console.log(res)
      setAlertContent("Incident " + res.data.data.result.number + " created successfully!");
      setAlert(true);
      setAlertType("success");
      await getSRList();
      setAlert(false);
      //setTimeout(setAlert(false),3000)

    } else {
      setAlertContent("Error while creating incident, please try again.");
      setAlert(true);
      //setTimeout(setAlert(false),3000)
      setAlertType("error");
    }
  }

  return (
    <div>
      <header>
        <PrimarySearchAppBar></PrimarySearchAppBar>
      </header>
      <main>
        <Box sx={{ flexGrow: 1, margin: '1%' }}>

          {!visible && (<Grid container spacing={2}><Grid size={12}><Paper elevation={2} sx={{ padding: '2%' }}>
            {alert ? <Alert severity={alertType}>{alertContent}</Alert> : <></>}
            <h3 style={{ display: 'inline-block' }}>My Incidents</h3>
            <Button sx={{ float: 'right', padding: '11px', marginLeft: '11px' }} variant="outlined" onClick={handleNewIncOpen} >New</Button>
            {(userRole === 'itil' || userRole === 'super_admin') && <Button sx={{ float: 'right', padding: '11px', marginLeft: '11px' }} variant="outlined" onClick={() => { filterMyAssignment('assigned') }} >Assigned to me</Button>}
            {(userRole === 'itil' || userRole === 'super_admin') && <Button sx={{ float: 'right', padding: '11px', marginLeft: '11px' }} variant="outlined" onClick={() => { filterMyAssignment('grp_assigned') }} >Assigned to my group</Button>}
            {(userRole === 'itil' || userRole === 'super_admin') && <Button sx={{ float: 'right', padding: '11px', marginLeft: '11px' }} variant="outlined" onClick={() => { filterMyAssignment('my_inc') }} >My Incidents</Button>}
            {(userRole === 'itil' || userRole === 'super_admin') && <Button sx={{ float: 'right', padding: '11px', marginLeft: '11px' }} variant="outlined" onClick={() => { filterMyAssignment(null) }} >All</Button>}
            <DataGrid
              {...data}
              loading={tableLoading}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                loadingOverlay: {
                  noRowsVariant: 'linear-progress',
                  variant: 'linear-progress'
                },
              }}
              disableMultipleRowSelection={true}
              initialState={{
                pagination: { paginationModel },
                pinnedColumns: {
                  left: ['number'],
                },
                filter: incidentFilterModel
              }}
              columnVisibilityModel={{
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
                sys_id: false,
                assigned_to_val_table: false,
                assignment_group_val_table: false,
                caller_val_table: false
              }}
              filterModel={incidentFilterModel.filterModel}
              onFilterModelChange={(newFilterModel) => {
                let filter = { filterModel: newFilterModel };
                setIncidentFilterModel(filter)
              }
              }
              pageSizeOptions={[5, 10]}
              checkboxSelection={false}
              sx={{ border: 0 }}
              onRowClick={handleRowClick}
            />
          </Paper>
          </Grid></Grid>)}

          {visible &&
            (<Grid container spacing={2}>
              <Grid size={8}  >  <Paper elevation={2} sx={{ padding: '2%' }}>
                <Box>
                  <Button onClick={backToList} ><ArrowBackIosOutlinedIcon sx={{ color: '#53B146' }}></ArrowBackIosOutlinedIcon></Button>
                  <h3 style={{ display: 'inline' }}>{inc.number}</h3>
                </Box>
                <Divider sx={{ paddingTop: '2%', minWidth: '100%', marginBottom: '2%' }} variant="fullWidth" />

                <Grid container spacing={2}>

                  <TextField name="sys_created_on" label="Opened" defaultValue={inc.sys_created_on} disabled />
                  {(userRole === 'itil' || userRole === 'super_admin') &&
                    <TextField id="assigned_to_val" name="assigned_to_val" label="Assigned To" value={inc.assigned_to.display_value} onClick={() => handleModalOpenUsers("user", "assigned_to")} />}
                  {(userRole !== 'itil' && userRole !== 'super_admin') && <TextField name="assigned_to_val" label="Assigned To" value={inc.assigned_to.display_value} disabled />}

                  {(userRole === 'itil' || userRole === 'super_admin') &&
                    <TextField id="assignGrp" name="assignGrp" label="Assignment Group" value={inc.assignment_group.display_value} onClick={() => handleModalOpenUsers("userGrp", "assignment_group")} disabled />}
                  {(userRole !== 'itil' && userRole !== 'super_admin') && <TextField name="assignGrp" label="Assignment Group" value={inc.assignment_group.display_value} disabled />}
                  {(userRole === 'itil' || userRole === 'super_admin') &&
                    <FormControl id="impactElement" sx={{ width: '200px' }}>
                      <InputLabel id="impactlabel">Impact</InputLabel>
                      <Select
                        labelId="impactlabel"
                        id="impact"
                        label="Impact"
                        value={newIncImpact}
                        onChange={handleSelectChange}
                      >
                        <MenuItem value="1 - High">1 - High</MenuItem>
                        <MenuItem value="2 - Medium">2 - Medium</MenuItem>
                        <MenuItem value="3 - Low">3 - Low</MenuItem>
                      </Select>
                    </FormControl>
                  }

                  {(userRole !== 'itil' && userRole !== 'super_admin') && <TextField name="impact" label="Impact" defaultValue={inc.impact} disabled />}
                  {(userRole === 'itil' || userRole === 'super_admin') &&
                    <FormControl id="urgencyElement" sx={{ width: '200px' }}>
                      <InputLabel id="urgencylabel">Urgency</InputLabel>
                      <Select
                        labelId="urgencylabel"
                        id="urgency"
                        label="Urgency"
                        value={newIncUrgency}
                        onChange={handleSelectChangeUrg}
                      >
                        <MenuItem value="1 - High">1 - High</MenuItem>
                        <MenuItem value="2 - Medium">2 - Medium</MenuItem>
                        <MenuItem value="3 - Low">3 - Low</MenuItem>
                      </Select>
                    </FormControl>
                  }
                  {(userRole !== 'itil' && userRole !== 'super_admin') && <TextField name="urgency" label="Urgency" defaultValue={inc.urgency} disabled />}
                  {(userRole === 'itil' || userRole === 'super_admin') &&
                    <FormControl id="stateElement" sx={{ width: '200px' }}>
                      <InputLabel id="statelabel">State</InputLabel>
                      <Select
                        labelId="statelabel"
                        id="state"
                        label="State"
                        value={newState}
                        onChange={handleSelectChangeIncState}
                      >
                        <MenuItem value="New">New</MenuItem>
                        <MenuItem value="In Progress">In Progress</MenuItem>
                        <MenuItem value="On Hold">On Hold</MenuItem>
                        <MenuItem value="Resolved">Resolved</MenuItem>
                        <MenuItem value="Closed">Closed</MenuItem>
                        <MenuItem value="Cancelled">Cancelled</MenuItem>
                      </Select>
                    </FormControl>
                  }
                  {(userRole !== 'itil' && userRole !== 'super_admin') && <TextField name="state" label="State" defaultValue={inc.state} disabled />}

                  {(userRole === 'itil' || userRole === 'super_admin') &&
                    <TextField id="congif_item" name="congif_item" label="Configuration Item" value={inc.cmdb_ci.display_value} onClick={() => handleModalOpenUsers("ci", "cmdb_ci")} />}
                  {(userRole !== 'itil' && userRole !== 'super_admin') && <TextField name="congif_item" label="Configuration Item" value={inc.cmdb_ci.display_value} disabled />}

                  <TextField id="short_desc" name="short_description" label="Short Description" defaultValue={inc.short_description} multiline fullWidth={true} maxRows={2} />
                  <TextField id="desc" name="description" label="Description" defaultValue={inc.description} multiline fullWidth={true}
                    maxRows={4} />
                  {(userRole === 'itil' || userRole === 'super_admin') &&
                    <div>
                      <br />
                      <h4>Related Records</h4>
                      <Grid container spacing={2}>
                        <TextField id="parent_incident" name="parent_incident" label="Parent Incident" value={inc.parent_incident.display_value} onClick={() => handleModalOpenUsers("inc", "parent_incident")} />
                        <TextField id="rfc" name="rfc" label="Change Request" value={inc.rfc.display_value} onClick={() => handleModalOpenUsers("chg", "rfc")} />
                        <TextField id="caused_by" name="caused_by" label="Caused By Change" value={inc.caused_by.display_value} onClick={() => handleModalOpenUsers("chg", "caused_by")} />
                        <TextField id="problem_id" name="problem_id" label="Problem" value={inc.problem_id.display_value} onClick={() => handleModalOpenUsers("prb", "problem_id")} />
                      </Grid>
                    </div>
                  }
                  <br />
                  <h4>Work Notes</h4>
                  <Timeline
                    sx={{
                      [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2,
                      },
                    }}
                  >
                    {inc.workNotes.map((note) => (
                      <TimelineItem>
                        <TimelineOppositeContent color="textSecondary">
                          {note.date}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>{note.text}</TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                  <TextField id="new_notes" name="new_notes" label="Add Notes" placeholder="Add your notes here" fullWidth={true} multiline maxRows={4} />

                  {(userRole === 'itil' || userRole === 'super_admin') &&
                    <div style={{ width: '100%' }}>
                      <br />
                      <h4>Resolution Information</h4>
                      <Grid container spacing={2}>
                        <FormControl>
                          <FormControlLabel control={<Checkbox name='is_knowledge' id='is_knowledge' checked={newKnowledge} onChange={handleKnowledgeToggle} />} label="Knowledge" />
                        </FormControl>
                        <FormControl sx={{ width: '300px' }}>
                          <InputLabel id="resolutionCodelabel">Resolution Code</InputLabel>
                          <Select
                            labelId="resolutionCodelabel"
                            id="resolutionCode"
                            value={resolutionCode}
                            label="Resolution Code"
                            onChange={handleSelectChangeIncResolutionCode}
                            autoWidth
                          >
                            <MenuItem value="">None</MenuItem>
                            <MenuItem value="Duplicate">Duplicate</MenuItem>
                            <MenuItem value="Known error">Known error</MenuItem>
                            <MenuItem value="No resolution provided">No resolution provided</MenuItem>
                            <MenuItem value="Resolved by caller">Resolved by caller</MenuItem>
                            <MenuItem value="Resolved by change">Resolved By change</MenuItem>
                            <MenuItem value="Resolved by problem">Resolved By problem</MenuItem>
                            <MenuItem value="Resolved by request">Resolved By request</MenuItem>
                            <MenuItem value="Solution provided">Solution provided</MenuItem>
                            <MenuItem value="Workaround provided">Workaround provided</MenuItem>
                            <MenuItem value="User error">User error</MenuItem>
                          </Select>
                        </FormControl>
                        <TextField id="resolution_notes" name="resolution_notes" defaultValue={inc.close_notes} label="Resolution Notes" placeholder="Add your resolution here" fullWidth={true} multiline maxRows={10} />

                      </Grid>
                    </div>
                  }
                  {refContent.kb.length > 0 && <div style={{ width: '100%' }}>
                    <br />
                    <h4>Attachments</h4>
                    {refContent.kb.map((card, index) => (
                      <ResourceCardMsg kb={card} inc={null}></ResourceCardMsg>
                    ))}
                  </div>}

                  <Stack spacing={2} direction="row" sx={{ float: 'right' }}>
                    <LoadingButton
                      sx={{ color: '#53B146', borderColor: '#53B146' }}
                      onClick={() => handleFormSubmit("save")}
                      loading={loading}
                      loadingPosition="start"
                      variant="outlined"
                    >
                      Save
                    </LoadingButton>
                    <LoadingButton
                      sx={{ color: '#53B146', borderColor: '#53B146' }}
                      onClick={() => handleFormSubmit("resolve")}
                      loading={loading}
                      loadingPosition="start"
                      variant="outlined"
                    >
                      Resolve
                    </LoadingButton>
                    <Button variant="outlined" sx={{ color: '#53B146', borderColor: '#53B146' }} onClick={() => { setVisible(false) }}>Cancel</Button>
                  </Stack>
                  {alert ? <Alert severity={alertType}>{alertContent}</Alert> : <></>}
                </Grid>

              </Paper> </Grid>
              <Grid size={4} >
                {(visible && botLoading) && <div style={{ top: '50%', left: '50%', transform: 'translate(5vh, 40vh)' }}>
                  <CircularProgress />
                  <span>&nbsp; Agent assist is working on it, please wait...</span>
                </div>}
                {(visible && botResponse && (userRole === 'itil' || userRole === 'super_admin')) && chatBotChild}
              </Grid></Grid>)}

          <Dialog
            id="modal_for_lists"
            open={openUser}
            onClose={handleClose}
            fullWidth
          >
            <DialogTitle>Select from the list</DialogTitle>
            <DialogContent>
              <DataGrid sx={{ padding: '1%', margin: '1%' }}
                {...modalData.data}
                initialState={{
                  pagination: { page: 0, pageSize: 20 },
                  pinnedColumns: {
                    left: ['number'],
                  }
                }}
                columnVisibilityModel={{
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                  sys_id: false,
                  manufacturer: false
                }}
                pageSizeOptions={[5, 10]}
                onRowClick={handleUserModalClick}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openNewInc}
            onClose={handleNewIncClose}
            fullWidth
            PaperProps={{
              component: 'form',
              onSubmit: (event) => {
                event.preventDefault();
                console.log(event)
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                submitNewIncident(formJson)
              },
            }}
          >
            <DialogTitle>Create A New Incident</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Enter the details to create an incident
              </DialogContentText>
              <TextField
                autoFocus
                required
                margin="dense"
                id="short_description"
                name="short_description"
                label="Short Description"
                type="text"
                fullWidth
              />
              <TextField
                autoFocus
                required
                margin="dense"
                id="description"
                name="description"
                label="Description"
                type="text"
                multiline
                fullWidth={true}
                maxRows={4}
              />
              <FormControl id="impactElement" sx={{ width: '200px', marginTop: '10px', marginRight: '10px' }}>
                <InputLabel id="impactlabel">Impact</InputLabel>
                <Select
                  labelId="impactlabel"
                  id="impact"
                  label="Impact"
                  value={newIncImpact}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="1">High</MenuItem>
                  <MenuItem value="2">Medium</MenuItem>
                  <MenuItem value="3">Low</MenuItem>
                </Select>
              </FormControl>
              <FormControl id="urgencyElement" sx={{ width: '200px', marginTop: '10px' }}>
                <InputLabel id="urgencylabel">Urgency</InputLabel>
                <Select
                  labelId="urgencylabel"
                  id="urgency"
                  label="Urgency"
                  value={newIncUrgency}
                  onChange={handleSelectChangeUrg}
                >
                  <MenuItem value="1">High</MenuItem>
                  <MenuItem value="2">Medium</MenuItem>
                  <MenuItem value="3">Low</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleNewIncClose}>Cancel</Button>
              <Button type="submit">Create</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </main>
    </div>
  );
};

export default MyIncidents;

/*
<ToggleButtonGroup
                  
                  color="primary"
                  value={incState}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                  sx={{ float: 'right' }}
                >
                  <ToggleButton value="open">Active</ToggleButton>
                  <ToggleButton value="closed">Closed</ToggleButton>
                </ToggleButtonGroup>
*/