import ChatWidget from "./ChatWidget";
import PrimarySearchAppBar from "./PrimarySearchAppBar";
import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import axios from "axios";
import { useEffect, useState } from "react";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, InputLabel, Link, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { FormContainer, TextFieldElement, TextareaAutosizeElement } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { LoadingButton } from "@mui/lab";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/system';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import { Block, Label } from "@mui/icons-material";
import { useForm } from "react-hook-form-mui";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { useSearchParams } from "react-router-dom";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

const hostName = 'https://kogniv-iservice-gvgmhpeuf5eaaghu.centralus-01.azurewebsites.net/api/main';
//const hostName = 'http://localhost:8000/api/main'

export default function MyApprovals() {
  const [srList, setSrList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [inc, setInc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [newApproval, setNewApproval] = useState('');
  const [newState, setNewState] = useState('');
  const [botLoading, setBotLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('success');
  const [value, setValue] = React.useState('1');
  const [approver, setApprover] = useState(null);
  const [openNewInc, setOpenNewInc] = useState(false);
  const [incidentFilterModel, setIncidentFilterModel] = useState({ filterModel: { items: [] } })

  let filterParam = searchParams.get("filter")

  const filterMyAssignment = (filterParam) => {
    if (filterParam && (userRole === 'itil' || userRole === 'super_admin')) {
      if (filterParam.includes('grp_assigned')) {
        /*incidentFilterModel = {
          filterModel: {
            items: [{ field: 'assignment_group_val_table', operator: 'contains', value: userID }]
          }
        }*/
        setIncidentFilterModel({ filterModel: { items: [] } })
        setSearchParams("filter=grp_assigned")
      } else if (filterParam.includes('assigned')) {
        let filter = {
          filterModel: {
            items: [{ field: 'approver_val_table', operator: 'contains', value: userID }]
          }
        }
        setIncidentFilterModel(filter)
        setSearchParams("filter=assigned")
      } else if (filterParam.includes('my_approval')) {
        let filter = {
          filterModel: {
            items: [{ field: 'approver_val_table', operator: 'contains', value: userID }]
          }
        }
        setIncidentFilterModel(filter)
        setSearchParams("filter=my_approval")
      }
    } else {
      setIncidentFilterModel({ filterModel: { items: [] } })
      setSearchParams("filter=null")
    }
  }

  const handleNewIncOpen = () => {
    setOpenNewInc(true);
  };

  const handleNewIncClose = () => {
    setOpenNewInc(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectChange = (event) => {
    setNewApproval(event.target.value);
  };

  const handleSelectChangeIncState = (event) => {
    setNewState(event.target.value);
  };


  let userRole = localStorage.getItem("role").toLocaleLowerCase();
  userRole = userRole.substring(1, userRole.length - 1);
  let userID = localStorage.getItem("userID");
  userID = userID.substring(1, userID.length - 1)
  const getSRList = async () => {
    try {
      filterMyAssignment(filterParam)
      setTableLoading(true);
      const token = localStorage.getItem("token");
      let user = localStorage.getItem("user").toLocaleLowerCase();
      user = user.substring(1, user.length - 1)
      axios.defaults.headers.post['x-api-key'] = `test`;
      axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.post['Content-Type'] = 'application/json';
      let data = {}
      if (userRole === 'approver') {
        data = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "sysapprover_update",
            "methodType": "get",
            "query": {
              "sysparm_query": "state=Requested^approver=" + userID,
              "sysparm_display_value": "true",
              "sysparm_fields": "approver,sysapproval,due_date,state,sys_created_on,sys_id"
            }
          }
        }
      } else if (userRole === 'itil' || userRole === 'super_admin') {
        data = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "sysapprover_update",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "state": "Requested"
              },
              "sysparm_display_value": "true",
              "sysparm_fields": "approver,sysapproval,due_date,state,sys_created_on,sys_id"
            }
          }
        }
      }

      let res = axios.post(`${hostName}`, data).then((res) => {
        if (res.status === 200) {
          let data = [];
          let closedData = [];
          if (userRole === 'employee' || userRole === 'approver') {
            for (const [i, row] of res.data.data.result.entries()) {
              row.id = i
              if (typeof row.approver === 'object') {
                row.approver_table = row.approver.display_value
                row.approver_val_table = row.approver.link
              }
              if (typeof row.sysapproval === 'object') {
                row.sysapproval_table = row.sysapproval.display_value
                row.sysapproval_val_table = row.sysapproval.link
              }
              data.push(row)
            }

          } else if (userRole === 'itil' || userRole === 'super_admin') {
            console.log("data in itil>>> ", res.data)
            for (const [i, row] of res.data.data.result.entries()) {
              row.id = i
              if (typeof row.approver === 'object') {
                row.approver_table = row.approver.display_value
                row.approver_val_table = row.approver.link
              }
              if (typeof row.sysapproval === 'object') {
                row.sysapproval_table = row.sysapproval.display_value
                row.sysapproval_val_table = row.sysapproval.link
              }
            }
            data = res.data.data.result;
          }
          const SR = data;
          setSrList(SR);
          //setSrListClosed(closedData);
          setTableLoading(false);

        } else {
          console.log(res.status);
          setTableLoading(false);

        }
      })
    }
    catch (error) {
      console.log("error while fetching inc list:::", error)
      if (error.response.data.detail.includes("401")) {
        console.log("refresh token>>>");
      }
      setTableLoading(false);

    }
  };
  useEffect(() => {
    getSRList()
  }, []);

  const columns = [
    { field: 'id', headerName: 'ID', width: 70, hideable: true },
    { field: 'sys_id', headerName: 'Sys ID', width: 70, hideable: true },
    { field: 'sysapproval_table', headerName: 'Approval For', width: 130 },
    { field: 'sys_created_on', headerName: 'Created Date', width: 200, sortable: true, hideable: true },
    { field: 'approver_table', headerName: 'Approver', width: 300, hideable: true },
    { field: 'approver_val_table', headerName: 'Approver', width: 300, hideable: true },
    { field: 'state', headerName: 'Status', width: 100, hideable: true },
    { field: 'due_date', headerName: 'Due Date', width: 200, sortable: true, hideable: true }
  ];

  const rows = srList;

  const data = {
    "rows": rows,
    "columns": columns,
    "rowLength": rows.length,
    "maxColumns": 50
  }

  const paginationModel = { page: 0, pageSize: 10 };

  const handleRowClick = async (params, callBot) => {
    setAlert(false);
    let tempworkNotes = [];
    axios.defaults.headers.post['x-api-key'] = `test`;
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    let data = {
      "appID": 1,
      "params": {
        "data": null,
        "entity": "sysapprover_update",
        "methodType": "get",
        "query": {
          "sysparm_query": {
            "sys_id": params.row.sys_id
          },
          "sysparm_display_value": "true"
        }
      }
    }
    let res = await axios.post(`${hostName}`, data);
    if (res.status === 200) {
      if (res.data.data.result.length > 0) {
        params.row = res.data.data.result[0]
      }
    }
    console.log("row click called:: ", params.row)

    setNewState(params.row.state);

    if (params.row.comments === "") {
      params.row.workNotes = [];
    } else {
      params.row.comments = params.row.comments.replace('\n\n', '\n');
      tempworkNotes = params.row.comments.split('\n');
      let workNotes = [];
      let note = {}
      tempworkNotes = tempworkNotes.filter(e => e !== '');
      for (const [i, row] of tempworkNotes.entries()) {

        if (i % 2 === 0) {
          note = {}
          note.date = row;
        } else {
          note.text = row;
          workNotes.push(note);
        }
      }
      console.log("workNotes:: ", workNotes)
      params.row.workNotes = workNotes;
    }

    setInc(params.row);

    setVisible(true);
  };

  const backToList = () => {
    setVisible(false);
    getSRList();
  }

  const handleFormSubmit = async (buttonName) => {
    if (buttonName === 'update') {
      setLoading(true);
      const comments = document.getElementById('new_notes').value;

      let invalidSubmit = true;
      const payload = {}

      if (comments && comments !== '') {
        payload.comments = comments
        inc.workNotes.unshift({ text: payload.comments })
        invalidSubmit = false;
      }
      inc.new_state = newState;

      let fields = ['state'];
      for (const [i, row] of fields.entries()) {
        let field = 'new_' + row;
        if (inc[field] && inc[field] !== '' && inc[field] !== inc[row]) {
          payload[row] = inc[field]
          invalidSubmit = false;
        }
      }

      if (invalidSubmit) {
        setAlertContent("No data updated");
        setAlert(true);
        //setTimeout(setAlert(false),3000)
        setAlertType("error");
        setLoading(false);
      } else {

        console.log("payload>>>>", payload);
        try {
          const token = localStorage.getItem("token");
          axios.defaults.headers.patch['Authorization'] = `Bearer ${token.substring(1, token.length - 1)}`;
          axios.defaults.headers.patch['Access-Control-Allow-Origin'] = '*';
          axios.defaults.headers.patch['Content-Type'] = 'application/json';

          let data = {
            "appID": 1,
            "params": {
              "data": payload,
              "sysId": inc.sys_id,
              "entity": "sysapprover_update",
              "methodType": "patch",
              "query": null
            }
          }
          let res = await axios.post(`${hostName}`, data);
          console.log("response>>>>", res.status)
          setLoading(false);
          if (res.status === 200) {
            setAlertContent("Saved successfully!");
            setAlert(true);
            //setTimeout(setAlert(false),3000)
            setAlertType("success");
            document.getElementById('new_notes').value = '';
            delete inc.new_state;
            handleRowClick({ row: inc }, false)
          } else {
            setAlertContent("Error while updating the request, please try again.");
            setAlert(true);
            //setTimeout(setAlert(false),3000)
            setAlertType("error");
          }

        } catch (error) {
          console.log("catch>>>", error);
          setLoading(false);
          setAlertContent("Error while updating the notes, please try again.");
          setAlert(true);
          //setTimeout(setAlert(false),3000)
          setAlertType("error");
        }
      }
    } else if (buttonName === 'approve') {
      const payload = { state: "Approved" }
      try {
        const token = localStorage.getItem("token");
        axios.defaults.headers.patch['Authorization'] = `Bearer ${token.substring(1, token.length - 1)}`;
        axios.defaults.headers.patch['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.patch['Content-Type'] = 'application/json';

        let data = {
          "appID": 1,
          "params": {
            "data": payload,
            "sysId": inc.sys_id,
            "entity": "sysapprover_update",
            "methodType": "patch",
            "query": null
          }
        }
        let res = await axios.post(`${hostName}`, data);
        console.log("response>>>>", res.status)
        setLoading(false);
        if (res.status === 200) {
          setAlertContent("Saved successfully!");
          setAlert(true);
          //setTimeout(setAlert(false),3000)
          setAlertType("success");
          document.getElementById('new_notes').value = '';
          delete inc.new_state;
          handleRowClick({ row: inc }, false)
        } else {
          setAlertContent("Error while updating the status, please try again.");
          setAlert(true);
          //setTimeout(setAlert(false),3000)
          setAlertType("error");
        }

      } catch (error) {
        console.log("catch>>>", error);
        setLoading(false);
        setAlertContent("Error while updating the status, please try again.");
        setAlert(true);
        //setTimeout(setAlert(false),3000)
        setAlertType("error");
      }
    } else if (buttonName === 'reject') {
      const payload = { state: "Rejected" }
      try {
        const token = localStorage.getItem("token");
        axios.defaults.headers.patch['Authorization'] = `Bearer ${token.substring(1, token.length - 1)}`;
        axios.defaults.headers.patch['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.patch['Content-Type'] = 'application/json';

        let data = {
          "appID": 1,
          "params": {
            "data": payload,
            "sysId": inc.sys_id,
            "entity": "sysapprover_update",
            "methodType": "patch",
            "query": null
          }
        }
        let res = await axios.post(`${hostName}`, data);
        console.log("response>>>>", res.status)
        setLoading(false);
        if (res.status === 200) {
          setAlertContent("Saved successfully!");
          setAlert(true);
          //setTimeout(setAlert(false),3000)
          setAlertType("success");
          document.getElementById('new_notes').value = '';
          delete inc.new_state;
          handleRowClick({ row: inc }, false)
        } else {
          setAlertContent("Error while updating the status, please try again.");
          setAlert(true);
          //setTimeout(setAlert(false),3000)
          setAlertType("error");
        }

      } catch (error) {
        console.log("catch>>>", error);
        setLoading(false);
        setAlertContent("Error while updating the status, please try again.");
        setAlert(true);
        //setTimeout(setAlert(false),3000)
        setAlertType("error");
      }
    } 
  }

  return (
    <div>
      <header>
        <PrimarySearchAppBar></PrimarySearchAppBar>
      </header>
      <main>
        <Box sx={{ flexGrow: 1, margin: '1%' }}>
          {!visible && (<Grid container spacing={2}><Grid size={12}><Paper elevation={2} sx={{ padding: '2%' }}>
            {alert ? <Alert severity={alertType}>{alertContent}</Alert> : <></>}
            <h3 style={{ display: 'inline-block' }}>My Approvals</h3>
            <Button sx={{ float: 'right', padding: '11px', marginLeft: '11px' }} variant="outlined" onClick={handleNewIncOpen} >New</Button>
            {(userRole === 'itil' || userRole === 'super_admin') && <Button sx={{ float: 'right', padding: '11px', marginLeft: '11px' }} variant="outlined" onClick={() => { filterMyAssignment('assigned') }} >Assigned to me</Button>}
            {(userRole === 'itil' || userRole === 'super_admin') && <Button sx={{ float: 'right', padding: '11px', marginLeft: '11px' }} variant="outlined" onClick={() => { filterMyAssignment(null) }} >All</Button>}
            <DataGrid
              {...data}
              loading={tableLoading}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                loadingOverlay: {
                  noRowsVariant: 'linear-progress',
                  variant: 'linear-progress'
                },
              }}
              disableMultipleRowSelection={true}
              initialState={{
                pagination: { paginationModel },
                pinnedColumns: {
                  left: ['sysapproval_table'],
                },
                filter: incidentFilterModel
              }}
              columnVisibilityModel={{
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
                sys_id: false,
                sysapproval_val_table: false,
                approver_val_table: false
              }}
              filterModel={incidentFilterModel.filterModel}
              onFilterModelChange={(newFilterModel) => {
                let filter = { filterModel: newFilterModel };
                setIncidentFilterModel(filter)
              }
              }
              pageSizeOptions={[5, 10]}
              checkboxSelection={false}
              sx={{ border: 0 }}
              onRowClick={handleRowClick}
            />
          </Paper>
          </Grid></Grid>)}

          {visible &&
            (<Grid container spacing={2}>
              <Grid size={8}  >  <Paper elevation={2} sx={{ padding: '2%' }}>
                <Box>
                  <Button onClick={backToList} ><ArrowBackIosOutlinedIcon sx={{ color: '#53B146' }}></ArrowBackIosOutlinedIcon></Button>
                  <h3 style={{ display: 'inline' }}>{inc.document_id.display_value}</h3>
                </Box>
                <Divider sx={{ paddingTop: '2%', minWidth: '100%', marginBottom: '2%' }} variant="fullWidth" />

                <Grid container spacing={2}>

                  <TextField name="approver" label="Approver" defaultValue={inc.approver.display_value} disabled />
                  <FormControl id="stateElement" sx={{ width: '200px' }}>
                    <InputLabel id="statelabel">State</InputLabel>
                    <Select
                      labelId="statelabel"
                      id="state"
                      label="State"
                      value={newState}
                      onChange={handleSelectChangeIncState}
                    >
                      <MenuItem value="Not Yet Requested">Not Yet Requested</MenuItem>
                      <MenuItem value="Requested">Requested</MenuItem>
                      <MenuItem value="Approved">Approved</MenuItem>
                      <MenuItem value="Rejected">Rejected</MenuItem>
                      <MenuItem value="Cancelled">Cancelled</MenuItem>
                      <MenuItem value="No Longer Required">No Longer Required</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container spacing={2}>

                  <h4 style={{ marginBottom: '0px' }}>Work Notes</h4>
                  <Timeline
                    sx={{
                      [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2,
                      },
                    }}
                  >
                    {inc.workNotes.map((note) => (
                      <TimelineItem>
                        <TimelineOppositeContent color="textSecondary">
                          {note.date}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>{note.text}</TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                  <TextField id="new_notes" name="new_notes" label="Add Comments" placeholder="Add your comments here" fullWidth={true} multiline maxRows={4} />

                  <Stack spacing={2} direction="row" sx={{ float: 'right' }}>
                    <LoadingButton
                      sx={{ color: '#53B146', borderColor: '#53B146' }}
                      onClick={() => handleFormSubmit("update")}
                      loading={loading}
                      loadingPosition="start"
                      variant="outlined"
                    >
                      Update
                    </LoadingButton>
                    <LoadingButton
                      sx={{ color: '#53B146', borderColor: '#53B146' }}
                      onClick={() => handleFormSubmit("approve")}
                      loading={loading}
                      loadingPosition="start"
                      variant="outlined"
                    >
                      Approve
                    </LoadingButton>
                    <LoadingButton
                      sx={{ color: '#53B146', borderColor: '#53B146' }}
                      onClick={() => handleFormSubmit("reject")}
                      loading={loading}
                      loadingPosition="start"
                      variant="outlined"
                    >
                      Reject
                    </LoadingButton>
                    
                    <Button variant="outlined" sx={{ color: '#53B146', borderColor: '#53B146' }} onClick={() => { setVisible(false) }}>Cancel</Button>
                  </Stack>
                  {alert ? <Alert severity={alertType}>{alertContent}</Alert> : <></>}
                </Grid>

              </Paper> </Grid>
            </Grid>)}
        </Box>
      </main>
    </div>
  );
};

/*
<React.Fragment>
                <Select
                  className="CustomSelect"
                  slots={{
                    root: Button1,
                  }}
                  slotProps={{
                    listbox: { className: 'CustomSelect-listbox' },
                    popup: { className: 'CustomSelect-popup' },
                  }} name="state" label="State" defaultValue={inc.state}>
                  <Option className="CustomSelect-option" value="New">New</Option>
                  <Option className="CustomSelect-option" value="In Progress">In Progress</Option>
                  <Option className="CustomSelect-option" value="Pending">Pending</Option>
                  <Option className="CustomSelect-option" value="Resolved">Resolved</Option>
                  <Option className="CustomSelect-option" value="Closed">Closed</Option>
                </Select>
                <Styles/>
              </React.Fragment>

*/

const cyan = {
  50: '#E9F8FC',
  100: '#BDEBF4',
  200: '#99D8E5',
  300: '#66BACC',
  400: '#1F94AD',
  500: '#0D5463',
  600: '#094855',
  700: '#063C47',
  800: '#043039',
  900: '#022127',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Button1 = React.forwardRef(function Button1(props, ref) {
  const { ownerState, ...other } = props;
  return (
    <button type="button" {...other} ref={ref}>
      {other.children}
      <UnfoldMoreRoundedIcon />
    </button>
  );
});

Button1.propTypes = {
  children: PropTypes.node,
  ownerState: PropTypes.object.isRequired,
};

function useIsDarkMode() {
  const theme = useTheme();
  return theme.palette.mode === 'dark';
}