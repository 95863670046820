import { ListItemContent } from '@mui/joy';
import { Button, Card, CardContent, List, ListItem, ListItemButton, ListItemText, Paper, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import Carousel from 'react-material-ui-carousel';

const DynamicOptionsWidget = (props) => {
    let references = props.payload.references;

    const handleClick = (item) => {
        console.log("item selected::: ", props)
        props.state.selectCatalog(item)
    }

    return (
        <div>
            <Typography >Select from the list of Service catalogs available:</Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }} aria-label="catalogs">
                {references.map((item, i) =>
                    <ListItem disablePadding sx={{border: 'solid thin #cdcdcd'}} onClick={() => handleClick(item.name)} key={i}>
                        <ListItemButton>
                            <ListItemText inset primary={item.price? (item.name + ' ('+item.price+')'): (item.name)} secondary={item.desc}/>
                        </ListItemButton>
                    </ListItem>)}
            </List>
        </div>
    );
};

//carousel
/*
{<Carousel next={(next, active) => console.log(`we left ${active}, and are now at ${next}`)}
            prev={(prev, active) => console.log(`we left ${active}, and are now at ${prev}`)}
            autoPlay={false}
            swipe={true}
            navButtonsAlwaysVisible={true}
            fullHeightHover={false}
        >
            {
                references.map((item, i) => <Item key={i} item={item} />)
            }
        </Carousel >} 
*/

function Item(props) {
    return (
        <Paper style={{ width: '70%', marginLeft: '15%' }}>
            <Grid direction="column" >
                <div>{props.item.name}</div>
                <div>
                    <Typography noWrap={true} sx={{ color: 'text.secondary', mb: 0.5, ml: 0.5 }}>{props.item.desc}</Typography>
                    <Typography noWrap={true} sx={{ color: 'text.secondary', mb: 0.5, ml: 0.5 }}>{props.item.price}</Typography>
                </div>
                <div style={{ textAlignLast: 'center' }}>
                    <Button>Add</Button>
                </div>
            </Grid>
        </Paper>
    )
}
/*
           <Stack direction="row" spacing={3} style={{overflowX: "scroll"}}>
           {references.map((card, index) => (
               <Grid size={{ xs: 4, sm: 8, lg: 8 }}>
                   <Card onClick={() => { alert('clicked', card.name) }}>
                       <CardContent>
                           <Stack direction="column">
                               <div>{card.name}</div>
                               <div>
                                   <Typography noWrap={true} sx={{ color: 'text.secondary', mb: 0.5, ml: 0.5 }}>{card.desc}</Typography>
                                   <Typography noWrap={true} sx={{ color: 'text.secondary', mb: 0.5, ml: 0.5 }}>{card.price}</Typography>
                                   <Button>Add</Button>
                               </div>
                           </Stack>
                       </CardContent>
                   </Card>
               </Grid>
           ))}
           </Stack>
       */

export default DynamicOptionsWidget;