import ChatWidget from "./ChatWidget";
import PrimarySearchAppBar from "./PrimarySearchAppBar";
import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import axios from "axios";
import { useEffect, useState } from "react";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, InputLabel, Link, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { FormContainer, TextFieldElement, TextareaAutosizeElement } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { LoadingButton } from "@mui/lab";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/system';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import { Block, Label } from "@mui/icons-material";
import { useForm } from "react-hook-form-mui";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { useSearchParams } from "react-router-dom";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

const hostName = 'https://kogniv-iservice-gvgmhpeuf5eaaghu.centralus-01.azurewebsites.net/api/main';
//const hostName = 'http://localhost:8000/api/main'

export default function MyServiceRequests() {
  const [srList, setSrList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [inc, setInc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [newApproval, setNewApproval] = useState('');
  const [newState, setNewState] = useState('');
  const [botLoading, setBotLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('success');
  const [value, setValue] = React.useState('1');
  const [approver, setApprover] = useState(null);
  const [openNewInc, setOpenNewInc] = useState(false);
  const [ritm, setRitm] = useState(null);
  const [catItem, setCatItem] = useState([]);
  const [openRitm, setOpenRitm] = useState(false);
  const [selectedRitm, setSelectedRitm] = useState(null)
  const [incidentFilterModel, setIncidentFilterModel] = useState({ filterModel: { items: [] } })

  let filterParam = searchParams.get("filter")

  const filterMyAssignment = (filterParam) => {
    if (filterParam && (userRole === 'itil' || userRole === 'super_admin')) {
      if (filterParam.includes('grp_assigned')) {
        /*incidentFilterModel = {
          filterModel: {
            items: [{ field: 'assignment_group_val_table', operator: 'contains', value: userID }]
          }
        }*/
        setIncidentFilterModel({ filterModel: { items: [] } })
        setSearchParams("filter=grp_assigned")
      } else if (filterParam.includes('assigned')) {
        let filter = {
          filterModel: {
            items: [{ field: 'assigned_to_val_table', operator: 'contains', value: userID }]
          }
        }
        setIncidentFilterModel(filter)
        setSearchParams("filter=assigned")
      } else if (filterParam.includes('my_sr')) {
        let filter = {
          filterModel: {
            items: [{ field: 'requested_for_val_table', operator: 'contains', value: userID }]
          }
        }
        setIncidentFilterModel(filter)
        setSearchParams("filter=my_sr")
      }
    } else {
      setIncidentFilterModel({ filterModel: { items: [] } })
      setSearchParams("filter=null")
    }
  }

  const handleNewIncOpen = () => {
    setOpenNewInc(true);
  };

  const handleNewIncClose = () => {
    setOpenNewInc(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectChange = (event) => {
    setNewApproval(event.target.value);
  };

  const handleSelectChangeIncState = (event) => {
    setNewState(event.target.value);
  };


  let userRole = localStorage.getItem("role").toLocaleLowerCase();
  userRole = userRole.substring(1, userRole.length - 1);
  let userID = localStorage.getItem("userID");
  userID = userID.substring(1, userID.length - 1)
  const getSRList = async () => {
    try {
      filterMyAssignment(filterParam)
      setTableLoading(true);
      const token = localStorage.getItem("token");
      let user = localStorage.getItem("user").toLocaleLowerCase();
      user = user.substring(1, user.length - 1)
      axios.defaults.headers.post['x-api-key'] = `test`;
      axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.post['Content-Type'] = 'application/json';
      let data = {}
      if (userRole === 'employee' || userRole === 'approver') {
        data = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "list_sr",
            "methodType": "get",
            "query": {
              "sysparm_query": "requested_for=" + userID + "^active=true",
              "sysparm_fields": "number,state,sys_id,sys_created_on,priority,short_description,due_date,assigned_to,assignment_group,approval,requested_for",
              "sysparm_display_value": "true"
            }
          }
        }
      } else if (userRole === 'itil' || userRole === 'super_admin') {
        data = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "list_sr",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "active": true
              },
              "sysparm_fields": "number,state,sys_id,sys_created_on,priority,short_description,due_date,assigned_to,assignment_group,approval,requested_for",
              "sysparm_display_value": "true"
            }
          }
        }
      }

      let res = axios.post(`${hostName}`, data).then((res) => {
        if (res.status === 200) {
          let data = [];
          let closedData = [];
          if (userRole === 'employee' || userRole === 'approver') {
            for (const [i, row] of res.data.data.result.entries()) {
              row.id = i
              if (typeof row.assigned_to === 'object') {
                row.assigned_to_table = row.assigned_to.display_value
                row.assigned_to_val_table = row.assigned_to.link
              }
              if (typeof row.assignment_group === 'object') {
                row.assignment_group_table = row.assignment_group.display_value
                row.assignment_group_val_table = row.assignment_group.link
              }
              if (typeof row.requested_for === 'object') {
                row.requested_for_val_table = row.requested_for.link
              }
              data.push(row)
            }

          } else if (userRole === 'itil' || userRole === 'super_admin') {
            console.log("data in itil>>> ", res.data)
            for (const [i, row] of res.data.data.result.entries()) {
              row.id = i
              if (typeof row.assigned_to === 'object') {
                row.assigned_to_table = row.assigned_to.display_value
                row.assigned_to_val_table = row.assigned_to.link
              }
              if (typeof row.assignment_group === 'object') {
                row.assignment_group_table = row.assignment_group.display_value
                row.assignment_group_val_table = row.assignment_group.link
              }
              if (typeof row.requested_for === 'object') {
                row.requested_for_val_table = row.requested_for.link
              }
            }
            data = res.data.data.result;
          }
          const SR = data;
          setSrList(SR);
          //setSrListClosed(closedData);
          setTableLoading(false);

        } else {
          console.log(res.status);
          setTableLoading(false);

        }
      })
    }
    catch (error) {
      console.log("error while fetching inc list:::", error)
      if (error.response.data.detail.includes("401")) {
        console.log("refresh token>>>");
      }
      setTableLoading(false);

    }
  };
  useEffect(() => {
    getSRList()
  }, []);

  const columns = [
    { field: 'id', headerName: 'ID', width: 70, hideable: true },
    { field: 'number', headerName: 'Request ID', width: 130 },
    { field: 'sys_created_on', headerName: 'Created Date', width: 200, sortable: true, hideable: true },
    { field: 'priority', headerName: 'Priority', width: 200, hideable: true },
    { field: 'approval', headerName: 'Approval', width: 100, hideable: true },
    { field: 'state', headerName: 'Status', width: 100, hideable: true },
    { field: 'short_description', headerName: 'Short Description', sortable: false, width: 300, hideable: true },
    { field: 'due_date', headerName: 'Due Date', width: 200, sortable: true, hideable: true },
    { field: 'assigned_to_table', headerName: 'Assigned To', width: 150, sortable: true, hideable: true, filterable: true },
    { field: 'assignment_group_table', headerName: 'Assignment Group', width: 250, sortable: true, hideable: true, filterable: true },
    { field: 'assigned_to_val_table', headerName: 'Assigned To val', width: 150, sortable: true, hideable: true, filterable: true },
    { field: 'assignment_group_val_table', headerName: 'Assignment Group val', width: 250, sortable: true, hideable: true, filterable: true },
    { field: 'requested_for_val_table', headerName: 'Requested For ID', width: 250, sortable: true, hideable: true, filterable: true }
  ];

  const rows = srList;

  const data = {
    "rows": rows,
    "columns": columns,
    "rowLength": rows.length,
    "maxColumns": 50
  }

  const paginationModel = { page: 0, pageSize: 10 };

  const handleRowClick = async (params, callBot) => {
    setAlert(false);
    let tempworkNotes = [];
    if (userRole === 'itil' || userRole === 'super_admin') {
      //getKnowledgeresponse(params.row.short_description);
    } else {
      setBotLoading(false)
    }

    axios.defaults.headers.post['x-api-key'] = `test`;
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    let data = {
      "appID": 1,
      "params": {
        "data": null,
        "entity": "list_sr",
        "methodType": "get",
        "query": {
          "sysparm_query": {
            "sys_id": params.row.sys_id
          },
          "sysparm_display_value": "true"
        }
      }
    }
    let res = await axios.post(`${hostName}`, data);
    if (res.status === 200) {
      if (res.data.data.result.length > 0) {
        params.row = res.data.data.result[0]
      }
    }
    console.log("row click called:: ", params.row)

    setNewApproval(params.row.approval);
    setNewState(params.row.request_state);

    if (params.row.comments_and_work_notes === "") {
      params.row.workNotes = [];
    } else {
      params.row.comments_and_work_notes = params.row.comments_and_work_notes.replace('\n\n', '\n');
      tempworkNotes = params.row.comments_and_work_notes.split('\n');
      let workNotes = [];
      let note = {}
      tempworkNotes = tempworkNotes.filter(e => e !== '');
      for (const [i, row] of tempworkNotes.entries()) {

        if (i % 2 === 0) {
          note = {}
          note.date = row;
        } else {
          note.text = row;
          workNotes.push(note);
        }
      }
      console.log("workNotes:: ", workNotes)
      params.row.workNotes = workNotes;
    }

    setInc(params.row);

    data = {
      "appID": 1,
      "params": {
        "data": null,
        "sysId": null,
        "entity": "sysapprover_update",
        "methodType": "get",
        "query": {
          "sysparm_query": {
            "sysapproval": params.row.sys_id
          },
          "sysparm_display_value": "true",
          "sysparm_fields": "approver,comments,sys_created_on,state"
        }
      }
    }

    res = await axios.post(`${hostName}`, data);
    if (res.status === 200) {
      if (res.data.data.result.length > 0) {
        setApprover(res.data.data.result)
      }
    }

    data = {
      "appID": 1,
      "params": {
        "data": null,
        "sysId": null,
        "entity": "list_ritm",
        "methodType": "get",
        "query": {
          "sysparm_query": {
            "request": params.row.sys_id
          },
          "sysparm_display_value": "true"
        }
      }
    }

    res = await axios.post(hostName, data);
    if (res.status === 200) {
      if (res.data.data.result.length > 0) {
        setRitm(res.data.data.result)
      }
    }

    for (const [i, item] of res.data.data.result.entries()) {
      let cat_item = item.cat_item.link.split("/")
      cat_item = cat_item[cat_item.length - 1]
      console.log("RITM:::: ", cat_item)
      data = {
        "appID": 1,
        "params": {
          "data": null,
          "sysId": null,
          "entity": "list_cat_item",
          "methodType": "get",
          "query": {
            "sysparm_query": {
              "sys_id": cat_item
            },
            "sysparm_display_value": "true"
          }
        }
      }

      let res1 = await axios.post(hostName, data);
      if (res1.status === 200) {
        if (res1.data.data.result.length > 0) {
          let temp = res1.data.data.result[0];
          data = {
            "appID": 1,
            "params": {
              "data": null,
              "sysId": null,
              "entity": "list_cat_item_var",
              "methodType": "get",
              "query": {
                "sysparm_query": {
                  "cat_item": temp.sys_id
                },
                "sysparm_display_value": "true"
              }
            }
          }

          let res2 = await axios.post(hostName, data);
          if (res2.status === 200) {
            if (res2.data.data.result.length > 0) {
              temp.item_variables = res2.data.data.result;
            }
          }
          if (catItem) {
            catItem.push(temp);
          }
        }
      }
    }

    setVisible(true);
  };

  const backToList = () => {
    setVisible(false);
    getSRList();
  }

  const handleRITMClick = (ritmNo) => {
    setOpenRitm(true)
    for (const [i, item] of ritm.entries()) {
      if (item.number === ritmNo) {
        console.log("RITM Selected:::: ", item)
        let tempworkNotes = [];
        if (item.comments_and_work_notes === "") {
          item.workNotes = [];
        } else {
          item.comments_and_work_notes = item.comments_and_work_notes.replace('\n\n', '\n');
          tempworkNotes = item.comments_and_work_notes.split('\n');
          let workNotes = [];
          let note = {}
          tempworkNotes = tempworkNotes.filter(e => e !== '');
          for (const [i, row] of tempworkNotes.entries()) {

            if (i % 2 === 0) {
              note = {}
              note.date = row;
            } else {
              note.text = row;
              workNotes.push(note);
            }
          }
          console.log("workNotes:: ", workNotes)
          item.workNotes = workNotes;
        }
        setSelectedRitm(item);
      }
    }
  }

  const handleRITMClose = () => {
    setOpenRitm(false);
  }

  const handleFormSubmit = async (buttonName) => {
    if (buttonName === 'save') {
      setLoading(true);
      const comments = document.getElementById('new_notes').value;
      const instructions = document.getElementById('instructions').value;
      const desc = document.getElementById("desc").value
      const shortdesc = document.getElementById("short_desc").value;
      const price = document.getElementById("price").value;

      let invalidSubmit = true;
      const payload = {}
      if (instructions && instructions !== '' && instructions != inc.special_instructions) {
        payload.special_instructions = instructions
        inc.special_instructions = instructions;
        invalidSubmit = false;
      }
      if (shortdesc && shortdesc !== '' && shortdesc != inc.short_description) {
        payload.short_description = shortdesc
        inc.short_description = shortdesc
        invalidSubmit = false;
      }
      if (desc && desc !== '' && desc != inc.description) {
        payload.description = desc
        inc.description = desc
        invalidSubmit = false;
      }
      if (comments && comments !== '') {
        payload.comments = comments
        inc.workNotes.unshift({ text: payload.comments })
        invalidSubmit = false;
      }
      if (price && price !== '' && price != inc.price) {
        payload.price = price
        inc.price = price
        invalidSubmit = false;
      }

      inc.new_approval = newApproval;
      inc.new_request_state = newState;

      let fields = ['request_state', 'approval'];
      for (const [i, row] of fields.entries()) {
        let field = 'new_' + row;
        if (inc[field] && inc[field] !== '' && inc[field] !== inc[row]) {
          payload[row] = inc[field]
          invalidSubmit = false;
        }
      }

      if (invalidSubmit) {
        setAlertContent("No data updated");
        setAlert(true);
        //setTimeout(setAlert(false),3000)
        setAlertType("error");
        setLoading(false);
      } else {

        console.log("payload>>>>", payload);
        try {
          const token = localStorage.getItem("token");
          axios.defaults.headers.patch['Authorization'] = `Bearer ${token.substring(1, token.length - 1)}`;
          axios.defaults.headers.patch['Access-Control-Allow-Origin'] = '*';
          axios.defaults.headers.patch['Content-Type'] = 'application/json';

          let data = {
            "appID": 1,
            "params": {
              "data": payload,
              "sysId": inc.sys_id,
              "entity": "list_sr",
              "methodType": "patch",
              "query": null
            }
          }
          let res = await axios.post(`${hostName}`, data);
          console.log("response>>>>", res.status)
          setLoading(false);
          if (res.status === 200) {
            setAlertContent("Saved successfully!");
            setAlert(true);
            //setTimeout(setAlert(false),3000)
            setAlertType("success");
            document.getElementById('new_notes').value = '';
            delete inc.new_approval;
            delete inc.new_state;
            handleRowClick({row:inc}, false)
          } else {
            setAlertContent("Error while updating the request, please try again.");
            setAlert(true);
            //setTimeout(setAlert(false),3000)
            setAlertType("error");
          }

        } catch (error) {
          console.log("catch>>>", error);
          setLoading(false);
          setAlertContent("Error while updating the notes, please try again.");
          setAlert(true);
          //setTimeout(setAlert(false),3000)
          setAlertType("error");
        }
      }
    }
  }

  const saveRitm = () => {

  }

  return (
    <div>
      <header>
        <PrimarySearchAppBar></PrimarySearchAppBar>
      </header>
      <main>
        <Box sx={{ flexGrow: 1, margin: '1%' }}>
          {!visible && (<Grid container spacing={2}><Grid size={12}><Paper elevation={2} sx={{ padding: '2%' }}>
            {alert ? <Alert severity={alertType}>{alertContent}</Alert> : <></>}
            <h3 style={{ display: 'inline-block' }}>My Service Requests</h3>
            <Button sx={{ float: 'right', padding: '11px', marginLeft: '11px' }} variant="outlined" onClick={handleNewIncOpen} >New</Button>
            {(userRole === 'itil' || userRole === 'super_admin') && <Button sx={{ float: 'right', padding: '11px', marginLeft: '11px' }} variant="outlined" onClick={() => { filterMyAssignment('assigned') }} >Assigned to me</Button>}
            {(userRole === 'itil' || userRole === 'super_admin') && <Button sx={{ float: 'right', padding: '11px', marginLeft: '11px' }} variant="outlined" onClick={() => { filterMyAssignment('grp_assigned') }} >Assigned to my group</Button>}
            {(userRole === 'itil' || userRole === 'super_admin') && <Button sx={{ float: 'right', padding: '11px', marginLeft: '11px' }} variant="outlined" onClick={() => { filterMyAssignment('my_sr') }} >My Service Requests</Button>}
            {(userRole === 'itil' || userRole === 'super_admin') && <Button sx={{ float: 'right', padding: '11px', marginLeft: '11px' }} variant="outlined" onClick={() => { filterMyAssignment(null) }} >All</Button>}
            <DataGrid
              {...data}
              loading={tableLoading}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                loadingOverlay: {
                  noRowsVariant: 'linear-progress',
                  variant: 'linear-progress'
                },
              }}
              disableMultipleRowSelection={true}
              initialState={{
                pagination: { paginationModel },
                pinnedColumns: {
                  left: ['number'],
                },
                filter: incidentFilterModel
              }}
              columnVisibilityModel={{
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
                sys_id: false,
                assigned_to_val_table: false,
                assignment_group_val_table: false,
                requested_for_val_table: false
              }}
              filterModel={incidentFilterModel.filterModel}
              onFilterModelChange={(newFilterModel) => {
                let filter = { filterModel: newFilterModel };
                setIncidentFilterModel(filter)
              }
              }
              pageSizeOptions={[5, 10]}
              checkboxSelection={false}
              sx={{ border: 0 }}
              onRowClick={handleRowClick}
            />
          </Paper>
          </Grid></Grid>)}

          {visible &&
            (<Grid container spacing={2}>
              <Grid size={8}  >  <Paper elevation={2} sx={{ padding: '2%' }}>
                <Box>
                  <Button onClick={backToList} ><ArrowBackIosOutlinedIcon sx={{ color: '#53B146' }}></ArrowBackIosOutlinedIcon></Button>
                  <h3 style={{ display: 'inline' }}>{inc.number}</h3>
                </Box>
                <Divider sx={{ paddingTop: '2%', minWidth: '100%', marginBottom: '2%' }} variant="fullWidth" />

                <Grid container spacing={2}>

                  <TextField name="sys_created_on" label="Opened" defaultValue={inc.sys_created_on} disabled />
                  <TextField id="requested_for" name="requested_for" label="Requested For" value={inc.requested_for.display_value} disabled />

                  <TextField name="opened_by" label="Opened By" value={inc.opened_by.display_value} disabled />
                  {(userRole === 'itil' || userRole === 'super_admin') &&
                    <FormControl id="approvalElement" sx={{ width: '200px' }}>
                      <InputLabel id="approvallabel">Approval</InputLabel>
                      <Select
                        labelId="approvallabel"
                        id="approval"
                        label="Approval"
                        value={newApproval}
                        onChange={handleSelectChange}
                      >
                        <MenuItem value="Not Yet Requested">Not Yet Requested</MenuItem>
                        <MenuItem value="Requested">Requested</MenuItem>
                        <MenuItem value="Approved">Approved</MenuItem>
                        <MenuItem value="Rejected">Rejected</MenuItem>
                      </Select>
                    </FormControl>
                  }

                  {(userRole !== 'itil' && userRole !== 'super_admin') && <TextField name="approval" label="Approval" defaultValue={newApproval} disabled />}
                  {(userRole === 'itil' || userRole === 'super_admin') &&
                    <FormControl id="stateElement" sx={{ width: '200px' }}>
                      <InputLabel id="statelabel">Request State</InputLabel>
                      <Select
                        labelId="statelabel"
                        id="state"
                        label="Request State"
                        value={newState}
                        onChange={handleSelectChangeIncState}
                      >
                        <MenuItem value="Pending Approval">Pending Approval</MenuItem>
                        <MenuItem value="Approved">Approved</MenuItem>
                        <MenuItem value="Closed Complete">Closed Complete</MenuItem>
                        <MenuItem value="Closed Incomplete">Closed Incomplete</MenuItem>
                        <MenuItem value="Closed Cancelled">Closed Cancelled</MenuItem>
                        <MenuItem value="Closed Rejected">Closed Rejected</MenuItem>
                        <MenuItem value="Closed Skipped">Closed Skipped</MenuItem>
                      </Select>
                    </FormControl>
                  }
                  <TextField id="due_date" name="due_date" label="Due Date" value={inc.due_date} disabled />
                  {(userRole !== 'itil' && userRole !== 'super_admin') && <TextField name="state" label="Request State" defaultValue={newState} disabled />}
                  <TextField id="price" name="price" label="Price" value={inc.price} />
                  <TextField id="short_desc" name="short_description" label="Short Description" defaultValue={inc.short_description} multiline fullWidth={true} maxRows={2} />
                  <TextField id="desc" name="description" label="Description" defaultValue={inc.description} multiline fullWidth={true}
                    maxRows={4} />
                  <TextField id="instructions" name="special_instructions" label="Special Instructions" defaultValue={inc.special_instructions} multiline fullWidth={true}
                    maxRows={4} />
                  <br />
                  <h4>Work Notes</h4>
                  <Timeline
                    sx={{
                      [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2,
                      },
                    }}
                  >
                    {inc.workNotes.map((note) => (
                      <TimelineItem>
                        <TimelineOppositeContent color="textSecondary">
                          {note.date}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>{note.text}</TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                  <TextField id="new_notes" name="new_notes" label="Add Notes" placeholder="Add your notes here" fullWidth={true} multiline maxRows={4} />
                  <br />
                  <Box sx={{ width: '100%' }}>
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                          <Tab label="Requested Items" value="1" />
                          <Tab label="Approvers" value="2" />
                          <Tab label="Recurring Prices" value="3" />
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                        {ritm && <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Number</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Catalog</TableCell>
                                <TableCell>Item</TableCell>
                                <TableCell>Due Date</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Assigned To</TableCell>
                                <TableCell>Stage</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {ritm.map((row) => (
                                <TableRow
                                  key={row.sys_id}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    <Link onClick={() => { handleRITMClick(row.number) }}>{row.number}</Link>
                                  </TableCell>
                                  <TableCell>{row.quantity}</TableCell>
                                  <TableCell>{row.sc_catalog}</TableCell>
                                  <TableCell>{row.short_description}</TableCell>
                                  <TableCell>{row.due_date}</TableCell>
                                  <TableCell>{row.price}</TableCell>
                                  <TableCell>{row.assigned_to}</TableCell>
                                  <TableCell>{row.stage}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>}
                        {!ritm && <Typography>No records to show</Typography>}
                      </TabPanel>
                      <TabPanel value="2">
                        {approver &&
                          <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>State</TableCell>
                                  <TableCell>Approver</TableCell>
                                  <TableCell>Comments</TableCell>
                                  <TableCell>Created</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {approver.map((row) => (
                                  <TableRow
                                    key={row.approver.display_value}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {row.state}
                                    </TableCell>
                                    <TableCell>{row.approver.display_value}</TableCell>
                                    <TableCell>{row.comments}</TableCell>
                                    <TableCell>{row.sys_created_on}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>}
                        {!approver && <Typography>No records to show</Typography>}
                      </TabPanel>
                      <TabPanel value="3">
                        {ritm && <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Item</TableCell>
                                <TableCell>Frequency</TableCell>
                                <TableCell>Price</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {ritm.map((row) => (
                                <TableRow
                                  key={row.sys_id}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell>{row.short_description}</TableCell>
                                  <TableCell>{row.recurring_frequency}</TableCell>
                                  <TableCell>{row.recurring_price}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>}
                        {!ritm && <Typography>No records to show</Typography>}
                      </TabPanel>
                    </TabContext>
                  </Box>
                  <Stack spacing={2} direction="row" sx={{ float: 'right' }}>
                    <LoadingButton
                      sx={{ color: '#53B146', borderColor: '#53B146' }}
                      onClick={() => handleFormSubmit("save")}
                      loading={loading}
                      loadingPosition="start"
                      variant="outlined"
                    >
                      Save
                    </LoadingButton>
                    <LoadingButton
                      sx={{ color: '#53B146', borderColor: '#53B146' }}
                      onClick={() => handleFormSubmit("add_item")}
                      loading={loading}
                      loadingPosition="start"
                      variant="outlined"
                    >
                      Add New Item
                    </LoadingButton>
                    <Button variant="outlined" sx={{ color: '#53B146', borderColor: '#53B146' }} onClick={() => { setVisible(false) }}>Cancel</Button>
                  </Stack>
                  {alert ? <Alert severity={alertType}>{alertContent}</Alert> : <></>}
                </Grid>

              </Paper> </Grid>
              <Grid size={4} >
                {(visible && botLoading) && <div style={{ top: '50%', left: '50%', transform: 'translate(5vh, 40vh)' }}>
                  {/*<CircularProgress />
                  <span>&nbsp; Agent assist is working on it, please wait...</span>*/}
                </div>}
                {/*(visible && botResponse && (userRole === 'itil' || userRole === 'super_admin')) && chatBotChild*/}
              </Grid></Grid>)}
          {selectedRitm && <Dialog
            open={openRitm}
            onClose={handleRITMClose}
            fullWidth
            maxWidth={"lg"}
            PaperProps={{
              component: 'form',
              onSubmit: (event) => {
                event.preventDefault();
                console.log(event)
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                saveRitm(formJson)
              },
            }}
          >
            <DialogTitle>Requested Item: {selectedRitm.number}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Requested Item Details:
              </DialogContentText>
              <Grid container spacing={2}>
                <TextField sx={{ marginTop: '10px', marginRight: '10px' }} name="itemName" label="Name" defaultValue={selectedRitm.cat_item.display_value} disabled />
                <TextField sx={{ marginTop: '10px', marginRight: '10px' }} name="opened_at" label="Opened" defaultValue={selectedRitm.opened_at} disabled />
                <TextField sx={{ marginTop: '10px', marginRight: '10px' }} name="request" label="Request" defaultValue={selectedRitm.request.display_value} disabled />
                <TextField sx={{ marginTop: '10px', marginRight: '10px' }} name="requested_for" label="Requested For" defaultValue={selectedRitm.requested_for.display_value} disabled />
                <TextField sx={{ marginTop: '10px', marginRight: '10px' }} name="quantity" label="Quantity" defaultValue={selectedRitm.quantity} disabled />
                <TextField sx={{ marginTop: '10px', marginRight: '10px' }} name="due_date" label="Due Date" defaultValue={selectedRitm.due_date} disabled />
                <TextField sx={{ marginTop: '10px', marginRight: '10px' }} name="stage" label="Stage" defaultValue={selectedRitm.stage} disabled />
                {(userRole === 'itil' || userRole === 'super_admin') &&
                  <FormControl id="stateElement" sx={{ width: '200px', marginTop: '10px', marginRight: '10px' }}>
                    <InputLabel id="statelabel">State</InputLabel>
                    <Select
                      labelId="statelabel"
                      id="state"
                      label="state"
                      value={selectedRitm.state}
                    >
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="Open">Open</MenuItem>
                      <MenuItem value="Work In Progress">Work In Progress</MenuItem>
                      <MenuItem value="Closed Complete">Closed Complete</MenuItem>
                      <MenuItem value="Closed Incomplete">Closed Incomplete</MenuItem>
                      <MenuItem value="Closed Skipped">Closed Skipped</MenuItem>
                    </Select>
                  </FormControl>}
                {(userRole !== 'itil' && userRole !== 'super_admin') &&
                  <TextField sx={{ marginTop: '10px', marginRight: '10px' }} id="state" name="state" label="State" value={selectedRitm.state} disabled />}
              </Grid>
              <div>
                <h4>Work Notes</h4>
                <Timeline
                  sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                      flex: 0.2,
                    },
                  }}
                >
                  {selectedRitm && selectedRitm.workNotes.map((note) => (
                    <TimelineItem>
                      <TimelineOppositeContent color="textSecondary">
                        {note.date}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>{note.text}</TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
                <TextField id="new_notes" name="new_notes" label="Add Notes" placeholder="Add your notes here" fullWidth={true} multiline maxRows={4} />
              </div>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleRITMClose}>Cancel</Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Dialog>}

        </Box>
      </main>
    </div>
  );
};

/*
<React.Fragment>
                <Select
                  className="CustomSelect"
                  slots={{
                    root: Button1,
                  }}
                  slotProps={{
                    listbox: { className: 'CustomSelect-listbox' },
                    popup: { className: 'CustomSelect-popup' },
                  }} name="state" label="State" defaultValue={inc.state}>
                  <Option className="CustomSelect-option" value="New">New</Option>
                  <Option className="CustomSelect-option" value="In Progress">In Progress</Option>
                  <Option className="CustomSelect-option" value="Pending">Pending</Option>
                  <Option className="CustomSelect-option" value="Resolved">Resolved</Option>
                  <Option className="CustomSelect-option" value="Closed">Closed</Option>
                </Select>
                <Styles/>
              </React.Fragment>

*/

const cyan = {
  50: '#E9F8FC',
  100: '#BDEBF4',
  200: '#99D8E5',
  300: '#66BACC',
  400: '#1F94AD',
  500: '#0D5463',
  600: '#094855',
  700: '#063C47',
  800: '#043039',
  900: '#022127',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Button1 = React.forwardRef(function Button1(props, ref) {
  const { ownerState, ...other } = props;
  return (
    <button type="button" {...other} ref={ref}>
      {other.children}
      <UnfoldMoreRoundedIcon />
    </button>
  );
});

Button1.propTypes = {
  children: PropTypes.node,
  ownerState: PropTypes.object.isRequired,
};

function useIsDarkMode() {
  const theme = useTheme();
  return theme.palette.mode === 'dark';
}