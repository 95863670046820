import React, { useEffect, useState } from 'react';
//import { useLocalStorageTokenHoc } from '../../hooks/useLocalStorageTokenHoc';
import axios from 'axios';
//import TypedAnimatedMessageSingle from '../ChatWidget/TypedAnimatedMessageSingle'
import parse from 'html-react-parser';
import { createClientMessage, createCustomMessage } from 'react-chatbot-kit';
import { v4 as uuidv4 } from 'uuid';
import axiosInstance from './../../axios';
import keyword_extractor from 'keyword-extractor';

const hostName = 'https://kogniv-iservice-gvgmhpeuf5eaaghu.centralus-01.azurewebsites.net/api/main';
//const hostName = 'http://localhost:8000/api/main'

const ActionProvider = ({ createChatBotMessage, setState, children, msg, botName, attach, state }) => {
  const attachToInc = (kb) => {
    attach(kb)
  }

  // Extracting the messages from the state
  const currentMessages = state.messages || [];
  let userID = localStorage.getItem("userID");
  userID = userID.substring(1, userID.length - 1)

  const selectCatalog = async (cat) => {
    console.log("selected catalog::: ", cat);
    let message = createClientMessage(cat);
    //let message = createClientMessage(cat.name);
    addMessageToState(message)
    callChatBotAPI(cat)
    
    /*let data = {
      "appID": 1,
      "params": {
        "data": {
          "sysparm_id": cat.sys_id,
          "sysparm_quantity": "1",
          "variables": {
            "request_type": "add",
            "user": userID
          }
        },
        "sysId": cat.sys_id,
        "entity": "sr_addtocart",
        "methodType": "post",
        "query": {
          "sys_id": cat.sys_id
        }
      }
    }

    let res = await axios.post(`${hostName}`, data);
    if (res.status === 200) {
      addMessageToState(createChatBotMessage('Added item to cart, do you want to checkout?', { widget: "options2" }));


      //
      data = {
        "appID": 1,
        "params": {
          "data": null,
          "entity": "sr_getcart",
          "methodType": "get",
          "query": {
            "sysparm_display_value": "true"
          }
        }
      }
      res = await axios.post(`${hostName}`, data);
      if (res.status === 200) {
        console.log(res)
      }

      //
    }
    */
  }
  const addMessageToState = (message) => {
    setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  };



  const removeMessageToState = (message) => {
    setState((prev) => {
      // Remove Loading here
      const newPrevMsg = prev.messages.slice(0, -1)
      console.log("newPrevMsg", newPrevMsg)
      return { ...prev, messages: [...newPrevMsg], }
    })
  };

  const clearStateMessages = () => {
    setState(prev => ({
      ...prev,
      messages: []
    }))
  };

  useEffect(() => {
    if (botName === 'knowledgeAssist') {
      //clearStateMessages();
      if (msg && currentMessages.length === 0) {
        setState((prevState) => ({
          ...prevState,
          attachToInc: attachToInc
        }));
        console.log("knowledge assist in action provider current messages>>>>", currentMessages);
        let uuid = uuidv4();
        try {

          msg = msg.replace('```json', '')
          msg = msg.replace('```html', '')
          msg = msg.replaceAll("\\\n", '')
          msg = msg.replaceAll("\\n", '')
          msg = msg.replace('```', "");
          msg = JSON.parse(msg);
          console.log("response::::: ", msg)

          let message = createCustomMessage([uuid, msg.msg], 'custom2', {
            "payload": { "uuid": uuid, "references": msg.references }, widget: "options", "loading": true
          });
          addMessageToState(message)

        } catch (err) {
          let message = createCustomMessage([uuid, "Sorry, Unable to get related information"], 'custom2', {
            "payload": { "uuid": uuid, "references": msg.references }, "loading": true
          });
          addMessageToState(message)
        }
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        selectCatalog: selectCatalog
      }));
    }
  }, []);

  const handleSelectResponse = async (props) => {
    let msg = props.target.innerHTML;
    console.log("message typed", msg);
    let usrMsg = createClientMessage(msg);
    addMessageToState(usrMsg);
    if (msg === 'Related incidents') {
      axios.defaults.headers.post['x-api-key'] = `test`;
      axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.post['Content-Type'] = 'application/json';
      let shortDesc = localStorage.getItem('shortDesc');
      let keywords = keyword_extractor.extract(shortDesc, {
        language: "english",
        remove_digits: true,
        return_changed_case: true,
        remove_duplicates: false
      });
      let query = ''
      for (const [i, word] of keywords.entries()) {
        if (i != 0) {
          query = query + '^ORshort_descriptionLIKE' + word
        } else {
          query = 'short_descriptionLIKE' + word
        }

      }

      let data = {
        "appID": 1,
        "params": {
          "data": null,
          "sysId": null,
          "entity": "incident",
          "methodType": "get",
          "query": {
            "sysparm_query": {
              "search": query,
              "state": "7"
            },
            "sysparm_fields": "number,short_description"
          }
        }
      }

      let res = await axios.post(`${hostName}`, data);
      if (res.status === 200) {
        let uuid = uuidv4();
        let refs = [];
        for (const [, ref] of res.data.data.result.entries()) {
          refs.push(ref.number)
        }
        let message = '';
        //refs = ['INC0000038','INC0000038','INC0000038','INC0000038','INC0000038']
        if (refs.length > 0) {
          message = createCustomMessage([uuid, "<p>Here are the related incidents to the issue:</p>"], 'custom2', { "payload": { "uuid": uuid, "references": refs }, widget: "options", "loading": true })
        } else {
          message = createCustomMessage([uuid, "<p>No related incidents found for this issue, how else can I help you?</p>"], 'custom2', { "payload": { "uuid": uuid, "references": [] }, "loading": true })
        }

        addMessageToState(message);
      }

    } else {
      callChatBotAPI(msg)
    }
  }

  const handleSelectResponse1 = async (props) => {
    let msg = props.target.innerHTML;
    console.log("message typed", msg);
    let usrMsg = createClientMessage(msg);
    addMessageToState(usrMsg);
    if (msg === 'Yes') {
      axios.defaults.headers.post['x-api-key'] = `test`;
      axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.post['Content-Type'] = 'application/json';

      let data = {
        "appID": 1,
        "params": {
          "data": null,
          "sysId": null,
          "entity": "sr_checkout",
          "methodType": "post",
          "query": null
        }
      }

      let res = await axios.post(`${hostName}`, data);
      if (res.status === 200) {
        let uuid = uuidv4();
        let refs = [];
        if (res.data.data.result.request_number) {
          let message = '';
        message = createCustomMessage([uuid, "<p>Request has been successfully created: " + res.data.data.result.request_number + "</p>"], 'custom2', { "payload": { "uuid": uuid, "references": refs }, "loading": true })
        
        addMessageToState(message);
        }
        
      }

    } 
  }

  const callServiceCatalogAPI = async (userMessage) => {
    try {
      if (botName === 'knowledgeAssist') {
        callChatBotAPI(userMessage)
      } else {
        axios.defaults.headers.post['x-api-key'] = `test`;
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.post['Content-Type'] = 'application/json';

        let data = {
          "appID": 1,
          "params": {
            "data": null,
            "sysId": null,
            "entity": "servicecatalog",
            "methodType": "get",
            "query": {
              "sysparm_text": userMessage,
              "sysparm_display_value": true
            }
          }
        }

        let res = await axios.post(`${hostName}`, data);
        if (res.status === 200 && res.data && res.data.data && res.data.data.result && res.data.data.result.length > 0) {
          let uuid = uuidv4();
          let refs = [];

          for (const [, ref] of res.data.data.result.entries()) {
            refs.push({ name: ref.name, price: ref.price, category: ref.category.title, desc: ref.short_description })
          }
          let message = createCustomMessage([uuid, ""], 'custom4', { "payload": { "uuid": uuid, "references": refs }, "loading": true })
          addMessageToState(message)
        } else {
          callChatBotAPI(userMessage)
        }
      }
    } catch (err) {
      console.log("error in bot", err)
      let uuid = uuidv4();
      let message = createCustomMessage([uuid, "Sorry, Unable to get related information"], 'custom2', {
        "payload": { "uuid": uuid, "references": msg.references }, "loading": true
      });
      addMessageToState(message);
    }
  }

  const callChatBotAPI = async (userMessage) => {
    try {
      const token = localStorage.getItem("token");
      console.log("message:::::::",)
      //create a loader message
      let dummyUUID = uuidv4();
      let dummyMsg = createCustomMessage([dummyUUID, 'Loading...'], 'custom3', { "payload": dummyUUID })
      addMessageToState(dummyMsg);


      let message = "";
      let url = '';
      let customHeaders = {};
      customHeaders['Authorization'] = 'Bearer ' + token.substring(1, token.length - 1);
      customHeaders['Access-Control-Allow-Origin'] = '*';
      if (botName === 'knowledgeAssist') {
        url = 'https://api.kogniv.com/api/v1/chat/agent-assist';
        customHeaders['Content-Type'] = 'application/x-www-form-urlencoded';
      } else {
        url = 'https://api.kogniv.com/api/v1/chat/employee-assist';
        customHeaders['Content-Type'] = 'application/json';
      }

      let threadID = null;
      let payload = {
        "message": userMessage
      }
      if (botName === 'employeeAssist') {
        threadID = localStorage.getItem('ea_conv_id')
        if (threadID.includes("null")) {
          threadID = null
        }
        let assets = JSON.parse(localStorage.getItem('snowAssets'))
        payload.details = JSON.parse(assets).details;
        if (threadID) {
          payload.thread_id = threadID;
        }
      } else {
        threadID = localStorage.getItem('aa_conv_id')
        if (threadID.includes("null")) {
          threadID = null
        }
        if (threadID) {
          url = url + `?thread_id=${threadID}`
        }
      }

      let uuid = uuidv4();


      const response = await axiosInstance.post(url, payload, { customHeaders });
      if (response.status === 200) {
        console.log('bot response:', response.data);
        if (threadID === null) {
          if (botName === 'employeeAssist') {
            localStorage.setItem('ea_conv_id', response.data.thread_id)
          } else {
            localStorage.setItem('aa_conv_id', response.data.thread_id)
          }
        }
        if (response.data.response === "") {
          message = createChatBotMessage(
            "How can I assist you today?"
          );
        } else {
          //if (botName === 'knowledgeAssist') {
          console.log("knowledge assist in action provider>>>>");
          let res = response.data.response;
          let content = '';

          res = res.replace('```json', '')
          res = res.replace('```html', '')
          res = res.replaceAll("\\\n", '')
          res = res.replaceAll("\\n", '')
          res = res.replace('```', "");
          res = JSON.parse(res);
          //console.log("response::::: ", JSON.parse(res))
          content = res.msg;
          removeMessageToState(dummyMsg);
          //addMessageToState(createChatBotMessage(parsedContent, 'custom2'))
          message = createCustomMessage([uuid, content], 'custom2', { "payload": { "uuid": uuid, "references": res.references, "catalog": res.catalog_code }, widget: "options", "loading": true })
        }
      } else {
        message = createChatBotMessage(
          "Sorry, I'm not able to help you with this. Could you ask something else please?"
        );
      }
      addMessageToState(message);
    } catch (err) {
      console.log("error in bot", err)
      let uuid = uuidv4();
      let message = createCustomMessage([uuid, "Sorry, Unable to get related information"], 'custom2', {
        "payload": { "uuid": uuid, "references": msg.references }, "loading": true
      });
      addMessageToState(message);
    }
  }



  const greet = async () => {
    let message = null;
    message = createChatBotMessage(
      "How can I help you today?"
    );

    addMessageToState(message);
  };


  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            callChatBotAPI,
            callServiceCatalogAPI,
            greet,
            handleSelectResponse,
            handleSelectResponse1
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
