import React, { useEffect } from 'react';
import Typed from 'typed.js';
import './chatbotCustomStyle.css';

const CustomLoadingMessage = (props) => {
    let message = '';
    console.log("current message:::", props);

    if (props.state.messages && props.state.messages.length > 0) {
        for (const [, msg] of props.state.messages.entries()) {
            if (typeof msg !== "string") {
                if ((props.payload === msg.payload) && msg.type === 'custom3') {

                    message = msg.message[1];
                }
            }
        }
    }

    // Create reference to store the DOM element containing the animation
    const el = React.useRef(null);

    useEffect(() => {
        /*const typed = new Typed(el.current, {
            strings: [message],
            typeSpeed: 40,
            showCursor: false,
            loop: true
        });
        

        // Set up MutationObserver to watch for changes
        const observer = new MutationObserver(() => {
            // Scroll the div to the bottom when content changes
            if (el.current) {
                //el.current.scrollTop = el.current.scrollHeight;
                props.scrollIntoView(({ behavior: "smooth", block: "end" }))
            }
        });

        // Options for MutationObserver to observe child elements and text changes
        const config = {
            childList: true,  // Observe additions/removals of child elements
            subtree: true,    // Observe the entire subtree of the div
            characterData: true, // Observe changes to text content
        };

        // Start observing the div element
        if (el.current) {
            observer.observe(el.current, config);
        }
        return () => {
            // Destroy Typed instance during cleanup to stop animation
            typed.destroy();
            if (observer && el.current) {
                observer.disconnect();
            }
        };
        */
    }, []);

    return (
        <div className='react-chatbot-kit-chat-bot-message-container'>
        <div className='react-chatbot-kit-chat-bot-message' >
            <div className='chatbot-loader-container'>
      <svg
        id='dots'
        width='50px'
        height='21px'
        viewBox='0 0 132 58'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g stroke='none' fill='none'>
          <g id='chatbot-loader' fill='#fff'>
            <circle id='chatbot-loader-dot1' cx='25' cy='30' r='13'></circle>
            <circle id='chatbot-loader-dot2' cx='65' cy='30' r='13'></circle>
            <circle id='chatbot-loader-dot3' cx='105' cy='30' r='13'></circle>
          </g>
        </g>
      </svg>
    </div></div>

            {/*
         <span ref={el} />
        */}
        </div>

        
       
    );
}

export default CustomLoadingMessage;