import React from "react";
import { createChatBotMessage, createCustomMessage } from "react-chatbot-kit";
import Options from "./options/Options";
import Options2 from "./options2/Options"
import Quiz from "./quiz/Quiz";
import CustomResolutionMessage from "../ChatWidget/CustomResolutionMessage";
import TypedAnimatedMessageSingle from "../ChatWidget/TypedAnimatedMessageSingle";
import TypingAnimatedMessage from "../ChatWidget/TypingAnimatedMessage";
import CustomLoadingMessage from "../ChatWidget/CustomLoadingMessage";
import DynamicOptionsWidget from "../ChatWidget/DynamicOptionsWidget";
import { Person3Outlined, SmartToyOutlined } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { useLocalStorage } from "../../hooks/useLocalStorage";

let user = localStorage.getItem('user');
if (typeof user === 'string') {
  user = JSON.parse(user)
  console.log(user);
}

const config = {
  botName: "IT Assist",
  initialMessages: [createChatBotMessage(`Hi ${user.first_name} ${user.last_name}, how may I help you today?`)
  ],
  customMessages: {
    custom: (props) => <CustomResolutionMessage {...props} />,
    custom2: (props) => <TypingAnimatedMessage {...props} attach={props.attachToInc} selectedItem={props.selectCatalog}/>,
    custom3: (props) => <CustomLoadingMessage {...props}/>,
    custom4: (props) => <DynamicOptionsWidget {...props} selectedItem={props.selectCatalog}/>
  },
  customComponents: {
    botAvatar: (props) => <Avatar sx={{color: 'hsl(220, 20%, 35%)', marginRight: '0.5em'}}><SmartToyOutlined fontSize="large" {...props}/></Avatar>,
    userAvatar: (props) => <Avatar sx={{color: 'hsl(220, 20%, 35%)', marginLeft: '0.5em'}}><Person3Outlined fontSize="large" {...props}/></Avatar>
  },
  widgets: [
    {
      widgetName: "options",
      widgetFunc: (props) => <Options {...props} />,
    },
    {
      widgetName: "options2",
      widgetFunc: (props) => <Options2 {...props} />,
    },
    {
      widgetName: "javascriptQuiz",
      widgetFunc: (props) => <Quiz {...props} />,
      props: {
        questions: [
          {
            question: "What is closure?",
            answer:
              "Closure is a way for a function to retain access to it's enclosing function scope after the execution of that function is finished.",
            id: 1,
          },
          {
            question: "Explain prototypal inheritance",
            answer:
              "Prototypal inheritance is a link between an object and an object store that holds shared properties. If a property is not found on the host object, javascript will check the prototype object.",
            id: 2,
          },
        ],
      },
    },
  ],
};

export default config;
