import { styles } from "./styles";
import React, { memo, useEffect, useRef, useState } from "react";
import Chatbot, { createChatBotMessage } from "react-chatbot-kit";
import 'react-chatbot-kit/build/main.css';
import config from "../ChatBot/config";
import MessageParser from "../ChatBot/MessageParser";
import ActionProvider from "../ChatBot/ActionProvider";
import Box from '@mui/material/Box';
import { v4 as uuidv4 } from 'uuid';
import './chatbotCustomStyle.css';
// 1st. argument is the text value, 2nd. argument is the name of the registered custom message.
const ChatbotMemo = memo(Chatbot)
function ChatWidget(params) {
    //const [shortDescForBot, setShortDescForBot] = useState(null);
    const [rendered, setRendered] = useState(false);
    const renderRef = useRef(rendered);
    // state variable to track if widget button was hovered on
    console.log("bot invoked>>> ", params)

    const attachToInc = (kb) => {
        params.attachment(kb)
    }
    //localStorage.setItem('entityType', params.entityType);
    //localStorage.setItem('entity', params);
    let botDisplayName = '';
    //const [hovered, setHovered] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => { setOpen(true); localStorage.setItem('conversation_id', uuidv4()) };
    const handleClose = (event) => {
        console.log('reason>>>>> ', event)
        setOpen(false);
    };
    localStorage.setItem('botName', params.botName);
    useEffect(() => {
        let entity = null;
        if (params.botName === 'employeeAssist') {
            entity = params.entity
        } else {
            entity = params.entity
        }
        console.log("short desc changed", entity)
        let msg = entity;
        if (entity != null && entity != '') {
            setOpen(true);
        }
    }, []);

    if (params.botName === 'employeeAssist') {
        botDisplayName = 'Employee Assist'
    } else if (params.botName === 'knowledgeAssist') {
        botDisplayName = 'Agent Assist'
        //localStorage.setItem('shortDesc', params.shortDesc)
    }

    const loadMessages = () => {
        let messages = null;
        console.log("history messages invoked:::")
        if (params.botName === 'employeeAssist') {
            //messages = JSON.parse(localStorage.getItem('chat_messages_ea'));
            messages = []
            let user = localStorage.getItem('user');
            if (typeof user === 'string') {
                user = JSON.parse(user)
                if (typeof user === 'string') {
                    user = JSON.parse(user)
                    console.log(user);
                }
            }
            messages.push(createChatBotMessage(`Hello ${user.first_name} ${user.last_name}, how may I assist you?`));
        } else if (params.botName === 'knowledgeAssist') {
            //messages = JSON.parse(localStorage.getItem('chat_messages_ka'));
            messages = []
        }
        if (messages === '' || messages === null) {
            messages = [];
        }


        console.log("messages in load messages:::: ", messages)
        return messages;
    };

    const saveMessages = (messages, HTMLString) => {
        console.log("saving message:::", messages)
        let tempMsgs = [];
        if (messages && messages.length > 0) {
            for (const [i, row] of messages.entries()) {
                if (row.message && row.message != '') {
                    tempMsgs.push(row)
                }
            }
        }
        messages = tempMsgs;

        if (params.botName === 'employeeAssist') {
            //localStorage.setItem('chat_messages_ea', JSON.stringify(messages));
        } else if (params.botName === 'knowledgeAssist') {
            //localStorage.setItem('chat_messages_ka', JSON.stringify(messages));
        }
    };

    return (
        <div style={{ height: 'calc(100vh - 100px)' }}>
            <Box sx={styles.chatModal}>
                {params.botName === 'knowledgeAssist' &&
                    <ChatbotMemo className={styles.reactchatbotkitchatbotmessage}
                        headerText={botDisplayName}
                        config={config}
                        messageHistory={loadMessages()}
                        actionProvider={memo((props) => <ActionProvider {...props} attach={attachToInc} msg={params.entity} botName={params.botName} />)}
                        messageParser={MessageParser}
                        saveMessages={saveMessages}
                    />}
                {params.botName === 'employeeAssist' &&
                    <ChatbotMemo className={styles.reactchatbotkitchatbotmessage}
                        headerText={botDisplayName}
                        config={config}
                        messageHistory={loadMessages()}
                        actionProvider={memo((props) => <ActionProvider {...props} msg={params.entity} botName={params.botName} />)}
                        messageParser={MessageParser}
                        saveMessages={saveMessages}
                    />}
            </Box>
        </div>
    );
}


export default memo(ChatWidget);


/*

 return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styles.chatModal}>
                    <Chatbot
                        config={config}
                        messageHistory={loadMessages()}
                        actionProvider={ActionProvider}
                        messageParser={MessageParser}
                        style={{ width: "100%", position: "relative" }}
                        saveMessages={saveMessages}
                    />
                </Box>
            </Modal>


            <div
                onClick={handleOpen}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                style={{
                    ...styles.chatWidget,
                    ...{ border: hovered ? "2px solid gray" : "" },
                }}
            >
                
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                   
                    <SupportAgentIcon
                        style={{ ...styles.chatWidgetIcon }} />
                    
                </div>
            </div>
        </div>
    );
*/